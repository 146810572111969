import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { Space } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { PageLayout } from "src/components/PageLayout";
import { observer } from "mobx-react-lite";
import { restCache } from "src/common/restCache";
import { EntityFiltersTable } from "./EntityFiltersTable";
import { EntityFiltersPageStore } from "./EntityFiltersPageStore";
import styles from "./EntityFiltersPage.module.less";
import { DefaultTSettingsStore } from "./DefaultTSettingsStore";
import { TableSettingsType } from "../ManagementPage/Obj2Tab/ObjTableSettings/ObjTableSettingsTypes";

export const EntityFiltersPage: React.FC = observer(() => {
  const { objectId } = useParams();
  const objectIdNum = objectId !== undefined ? Number(objectId) : undefined;
  const navigate = useNavigate();
  const store = useMemo(
    () =>
      new EntityFiltersPageStore({
        onRowClick({ id }) {
          navigate(makeUrl(PageUrl.entityCard, { id }));
        },
        defTSettingsStore: new DefaultTSettingsStore(
          TableSettingsType.entities,
        ),
      }),
    [],
  );
  useEffect(() => {
    if (!objectIdNum) return;
    store.setCurrObjId(objectIdNum);
  }, [objectIdNum]);

  useEffect(
    () => () => {
      restCache.invalidate();
    },
    [],
  );

  return (
    <PageLayout pageTitle={store.currObjName} documentTitle={store.currObjName}>
      {!objectIdNum ? (
        <Space className={styles.needSelect}>
          Выберите объект для работы <ArrowUpOutlined />
        </Space>
      ) : (
        <EntityFiltersTable store={store} />
      )}
    </PageLayout>
  );
});

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Alert, Button, DatePickerProps, Empty, Spin } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { mkColKey1, demo1Store as store } from "./Demo1Store";
import { ZDemo1Row } from "./ZDemo1Data";
import { CellPosGenerator } from "../Sheet/CellPosGenerator";
import { CellInput } from "../Sheet/CellInput";
import { PropsCellEditor } from "../Sheet/PropsCell";
import { CellText } from "../Sheet/CellText";
import { CellNumber } from "../Sheet/CellNumber";
import { CellSelect } from "../Sheet/CellSelect";
import { CellDatePicker } from "../Sheet/CellDatePicker";
import styles from "./Demo1.module.less";

export const Demo1: React.FC = observer(() => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <Spin spinning={store.loading}>
      <div className={styles.demo1}>
        <DrawError1 />
        {!store.error && <DrawTable1 />}
      </div>
    </Spin>
  );
});

const DrawError1: React.FC = observer(() => {
  const { error } = store;
  if (!error) return null;
  return (
    <>
      <Alert type="error" message={error.message} showIcon />
      <div>
        <Button type="primary" onClick={() => store.init()}>
          Reload
        </Button>
      </div>
    </>
  );
});

type ColDef1 = {
  field: keyof ZDemo1Row;
  title: string;
  render: (row: ZDemo1Row) => React.ReactNode;
};

const span = (count: number) => `span ${count}`;

const DrawTable1: React.FC = observer(() => {
  const { data } = store;
  const posGen = new CellPosGenerator();

  const colDef = <CompProps, BoxProps extends PropsCellEditor<CompProps>>(
    field: keyof ZDemo1Row,
    title: string,
    BoxComp: React.ComponentClass<BoxProps> | React.FC<BoxProps>,
    editorProps: CompProps,
    emptyValue: unknown,
    extraProps: Omit<BoxProps, "cell" | "editorProps">,
    validate?: (value: unknown) => Promise<void>,
  ): ColDef1 => ({
    field,
    title,
    render: (row) => {
      const cellKey = mkColKey1(row, field);
      const boxProps = {
        ...extraProps,
        cell: {
          cellKey,
          pos: posGen.next(field === columns[0]?.field),
          store: store.sheetStore,
          value: row[field],
          emptyValue,
          save: store.fieldSaver(row, field),
          validate,
        },
        editorProps,
      } as BoxProps;
      return (
        <div className={styles.cell}>
          <BoxComp {...boxProps} />
        </div>
      );
    },
  });
  const columns: ColDef1[] = [
    colDef("name", "Name", CellInput, {}, "", {}, async (value) => {
      if (!value) throw Error("Необходимо заполнить поле");
      if (value === "test") throw Error("Запрещено вводить test");
    }),
    colDef("email", "e-mail", CellInput, {}, null, {}, (value) =>
      typeof value === "string" && value && !/^.+@.+$/.test(value)
        ? Promise.reject(Error("Неправильный e-mail"))
        : Promise.resolve(),
    ),
    colDef(
      "mass",
      "Mass",
      // @ts-ignore
      CellNumber,
      { addonAfter: "кг", min: 0, max: 1000 },
      null,
      {},
    ),
    colDef(
      "comment",
      "Comment",
      // @ts-ignore
      CellText,
      { size: "small" } satisfies TextAreaProps,
      null,
      {},
    ),
    colDef(
      "status",
      "Status",
      // @ts-ignore
      CellSelect,
      {
        options: store.statusOptions,
        allowClear: true,
        optionFilterProp: "label",
        showSearch: true,
      },
      null,
      {},
      (value) =>
        value
          ? Promise.resolve()
          : Promise.reject(Error("Необходимо заполнить поле")),
    ),
    colDef(
      "date",
      "Date",
      // @ts-ignore
      CellDatePicker,
      {
        format: "DD.MM.YYYY",
      } satisfies DatePickerProps,
      null,
      {},
    ),
  ];

  return (
    <div>
      <div
        className={styles.table}
        style={{ gridTemplateColumns: `repeat(${columns.length}, 200px)` }}
      >
        {columns.map(({ field, title }) => (
          <div key={field} className={styles.cell}>
            {title}
          </div>
        ))}
        {!data.length && (
          <div
            className={styles.cell}
            style={{ gridColumn: span(columns.length) }}
          >
            <Empty />
          </div>
        )}
        {data.length > 0 &&
          data.map((row) => (
            <React.Fragment key={row.id}>
              {columns.map((col) => (
                <div key={col.field} className={styles.cell}>
                  {col.render(row)}
                </div>
              ))}
            </React.Fragment>
          ))}
      </div>
      <div className={styles.infoBlock}>
        <p>Условия, которые используются для отладки:</p>
        <ul>
          <li>
            Если в любой ячейке ввести <b>error</b>, то будет ошибка при
            сохранении.
          </li>
          <li>
            В поле Name значение должно быть заполнено и не равно <b>test</b>.
          </li>
          <li>В поле Mass значения имеют диапазон от 0 до 1000</li>
        </ul>
      </div>
      <pre>{store.data.map((row) => JSON.stringify(row)).join("\n")}</pre>
    </div>
  );
});

/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { ColumnsSettings } from "src/components/tables/ColumnsSettings";
import { AsyncTableView } from "../AsyncTableView";
import { EntityFilters, ZEntityRow } from "./types";
import styles from "./EntityList.module.less";
import { EntityFiltersPageStore } from "../EntityFiltersPageStore";
import { InputSearch } from "./InputSearch";
import { EntityActions } from "./EntityActions";
import { FilterSwitch } from "./FilterSwitch";
import { EntFilterActionType } from "../EntityFiltersPage.types";
import { DefaultViewButton } from "./DefaultViewButton";

interface PropsEntityList {
  store: EntityFiltersPageStore;
  onRowClick?(row: ZEntityRow, index?: number): void;
}

export const EntityList: React.FC<PropsEntityList> = observer(
  ({
    store,
    store: {
      tableStore,
      avalibleActionsSet,
      canCreate,
      currObjId,
      currObjName,
      defTSettingsStore,
    },
    onRowClick,
  }) => {
    if (!tableStore) return null;
    return (
      <div className={styles.content}>
        <AsyncTableView<ZEntityRow, EntityFilters>
          headerExtra={
            <div className={styles.header}>
              <div className={styles.headerInner}>
                <InputSearch store={tableStore} />
                <Space>
                  {avalibleActionsSet.has(
                    EntFilterActionType.changeFilterVisible,
                  ) && <FilterSwitch store={store} />}
                  <ColumnsSettings
                    store={tableStore}
                    extraButtons={
                      defTSettingsStore?.displayAttrs ? (
                        <DefaultViewButton
                          onClick={() => defTSettingsStore?.applySettings(true)}
                        />
                      ) : undefined
                    }
                  />
                  <EntityActions store={store} />
                  {canCreate && (
                    <Link
                      to={makeUrl(PageUrl.entityNew, {
                        objectId: currObjId,
                      })}
                    >
                      <Button type="primary" size="large">
                        <PlusCircleOutlined /> {currObjName}
                      </Button>
                    </Link>
                  )}
                </Space>
              </div>
            </div>
          }
          onRowClick={onRowClick}
          store={tableStore}
          columns={tableStore.columns}
          useHeader
          usePagination
          useSelection
        />
      </div>
    );
  },
);

import * as React from "react";
import { t } from "i18next";
import { DownOutlined } from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { MChartEntityStore } from "../../MChartEntityStore";
import { PointOpt } from "../MChartTable/PointDict";
import { FilterMcPoints, PointSelectModal } from "../PointSelectModal";
import styles from "./PointSelectExt.module.less";

interface PropsPointSelectExt {
  store: MChartEntityStore;
  value?: PointOpt;
  onChange: (newValue?: PointOpt) => void;
  onClose: () => void;
}

export const PointSelectExt = React.forwardRef(
  (props: PropsPointSelectExt, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { value, onChange, onClose, store } = props;
    const [tableStore, setTableStore] = React.useState<TableStore<
      PointOpt,
      FilterMcPoints
    > | null>(null);
    const close = () => {
      setTableStore(null);
      onClose();
    };
    const open = () => setTableStore(store.createPointSelectStore("radio"));
    return (
      <>
        <button
          className={styles.controlBox}
          onClick={open}
          type="button"
          ref={ref}
        >
          <div className={styles.controlValue}>{value?.label}</div>
          <DownOutlined />
        </button>
        <PointSelectModal
          title={t("Selecting a point")}
          tableStore={tableStore}
          close={close}
          success={(rows) => onChange(rows[0])}
          categoryList={store.categoryList}
          okText={t("Select")}
        />
      </>
    );
  },
);

PointSelectExt.defaultProps = {
  value: undefined,
};

import * as React from "react";
import { Cell2 } from "src/components/Sheet2/Cell2";
import { observer } from "mobx-react-lite";
import { CellInternalBox } from "src/components/Sheet2/components/CellInternalBox";
import { KeyDef } from "src/components/Sheet2/isSymbolKey";
import { ZMCRow } from "../../../ZMeasurementChart";
import { MChartEntityStore } from "../../MChartEntityStore";
import { makeMcPointKey } from "../../mChartCellKeys";
import { PointOpt } from "./PointDict";
import { PointSelectExt } from "../PointSelectExt";

interface PropsPointSelect {
  row: ZMCRow;
  store: MChartEntityStore;
  pos: Cell2["pos"];
}

export const PointSelect: React.FC<PropsPointSelect> = observer((props) => {
  const { row, store, pos } = props;
  const cellKey = makeMcPointKey(row, "point");
  const {
    mcPoint: {
      point: { id, name },
      description,
    },
  } = row;
  const isValueExist = !!id && !!name; // && !!description;
  const cellValue = isValueExist
    ? {
        value: id,
        label: name,
        desc: description ?? "",
      }
    : undefined;

  const pref = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    store.sheetStore.updateCell({
      viewOnly: true,
      emptyValue: null,
      cellKey,
      pos,
      focus() {
        pref.current?.focus();
      },
      blur() {
        pref.current?.blur();
      },
      save: async (rawValue) => {
        const po = rawValue as PointOpt;
        const res = await store.savePointValue(row, "point", {
          id: po.value,
          name: po.label,
        });
        return () => {
          store.updatePointValue(row, res, "point");
          store.sheetStore.setValueByKey(
            makeMcPointKey(row, "description"),
            po.desc,
          );
        };
      },
      equals: (a, b) => {
        if (!a && !b) return true;
        if (!a || !b) return false;
        return (
          typeof a === "object" &&
          typeof b === "object" &&
          "id" in a &&
          "id" in b &&
          a.id === b.id
        );
      },
    });
    const button = pref.current;
    const onKey = (e: KeyDef) => {
      if (e.key === "Enter") {
        e.preventDefault();
      } else if (e.key === "Delete") {
        return; // Нельзя удалять значение точки
      }
      store.sheetStore.onKeyDown(cellKey, e);
    };
    button?.addEventListener("keydown", onKey);
    return () => {
      button?.removeEventListener("keydown", onKey);
    };
  });

  React.useEffect(() => {
    store.sheetStore.setSrcCellValue(cellKey, cellValue);
  }, [id]);

  return (
    <CellInternalBox store={store.sheetStore} cellKey={cellKey}>
      <PointSelectExt
        ref={pref}
        store={store}
        value={cellValue}
        onChange={(v) => {
          store.sheetStore.setValueByKey(cellKey, v);
        }}
        onClose={() => {
          store.sheetStore.activate(cellKey, false);
        }}
      />
    </CellInternalBox>
  );
});

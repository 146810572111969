import * as React from "react";
import { Input } from "antd";
import { observer } from "mobx-react-lite";
import { TextAreaRef } from "antd/es/input/TextArea";
import { TextAreaProps } from "antd/lib/input";
import { CellInternalBox } from "./CellInternalBox";
import { PropsCellEditor } from "./PropsCell";

const { TextArea } = Input;

export const CellText: React.FC<PropsCellEditor<TextAreaProps>> = observer(
  ({ cell, editorProps }) => {
    const { cellKey } = cell;
    const { store, value, ...cellProps } = cell;
    const iref = React.useRef<TextAreaRef>(null);
    React.useEffect(() => {
      store.setCell({
        ...cellProps,
        focus() {
          iref.current?.focus();
        },
        blur() {
          iref.current?.blur();
        },
      });
    }, []);
    React.useEffect(() => {
      store.setSrcCellValue(cellKey, value);
    }, [value]);
    const isReadonly = store.isViewMode(cellKey);
    const errMsg = store.errors[cellKey];
    return (
      <CellInternalBox store={store} cellKey={cellKey}>
        <TextArea
          ref={iref}
          {...editorProps}
          status={errMsg ? "error" : undefined}
          value={String(store.cellCurValues[cellKey] ?? "")}
          onChange={(e) =>
            store.setCurCellValue(cellKey, e.currentTarget.value)
          }
          style={{ width: "100%" }}
          readOnly={isReadonly}
          onFocus={() => {
            store.activate(cellKey, false);
          }}
          onBlur={() => {
            store.blur(store.cellByKey(cellKey));
          }}
          onClick={() => {
            store.activate(cellKey, false);
          }}
          onDoubleClick={() => {
            store.activate(cellKey, true);
          }}
          onKeyDown={(e) => {
            if (e.key !== "Enter") {
              store.onKeyDown(cellKey, e);
            }
          }}
        />
      </CellInternalBox>
    );
  },
);

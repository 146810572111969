// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  box-shadow: 0px 2px 10px rgba(43, 43, 43, 0.11);
  border-radius: 4px;
  max-height: 70vh;
  overflow: hidden;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM .src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableList--yqsRY {
  overflow-y: auto;
  padding: 16px 0;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM .src-components-tables-ColumnsSettings-ColumnsSettings-module__menu--G1p1e {
  max-height: 300px;
  overflow: auto;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM .src-components-tables-ColumnsSettings-ColumnsSettings-module__extraButtons--JV_vY {
  width: 100%;
  border-top: 1px solid #0000000F;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__trigger--p25tx {
  padding: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/ColumnsSettings/ColumnsSettings.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,+CAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AATA;EAWI,gBAAA;EACA,eAAA;AACJ;AAbA;EAgBI,iBAAA;EACA,cAAA;AAAJ;AAjBA;EAqBI,WAAA;EACA,+BAAA;AADJ;AAKA;EACE,YAAA;EACA,eAAA;AAHF","sourcesContent":[".sortableContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  background: white;\n  box-shadow: 0px 2px 10px rgba(43, 43, 43, 0.11);\n  border-radius: 4px;\n  max-height: 70vh;\n  overflow: hidden;\n\n  .sortableList {\n    overflow-y: auto;\n    padding: 16px 0;\n  }\n\n  .menu {\n    max-height: 300px;\n    overflow: auto;\n  }\n\n  .extraButtons {\n    width: 100%;\n    border-top: 1px solid #0000000F;\n  }\n}\n\n.trigger {\n  padding: 5px;\n  cursor: pointer;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortableContainer": `src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM`,
	"sortableList": `src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableList--yqsRY`,
	"menu": `src-components-tables-ColumnsSettings-ColumnsSettings-module__menu--G1p1e`,
	"extraButtons": `src-components-tables-ColumnsSettings-ColumnsSettings-module__extraButtons--JV_vY`,
	"trigger": `src-components-tables-ColumnsSettings-ColumnsSettings-module__trigger--p25tx`
};
export default ___CSS_LOADER_EXPORT___;

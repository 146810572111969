import { observer } from "mobx-react-lite";
import React from "react";
import { Select } from "antd";
import { makeFieldOptions } from "src/common/makeFieldOptions";
import { ObjTableControlStore } from "../ObjTableControl/ObjTableControlStore";

interface PropsSortSelect {
  store: ObjTableControlStore;
}

export const SortSelect: React.FC<PropsSortSelect> = observer(({ store }) => {
  const { tableStore, sortableAttrs, isDisabled } = store;
  if (!tableStore) return null;
  const { sort, sortOrder, params } = tableStore;
  const handleChange = (value: number) => {
    tableStore.setParams({
      ...params,
      sort: value ? String(value) : undefined,
      sortOrder: value ? sortOrder ?? "ascend" : undefined,
    });
    tableStore.reload();
    store.saveSettings();
  };

  return (
    <Select
      disabled={isDisabled}
      options={makeFieldOptions(sortableAttrs)}
      value={sort ? Number(sort) : undefined}
      showSearch
      optionFilterProp="filterProp"
      allowClear
      onChange={handleChange}
      placeholder="Выбрать"
    />
  );
});

import React from "react";
import { Progress, TableProps } from "antd";
import { classNames } from "src/common/classNames";
import { t } from "i18next";
import { ColumnType } from "antd/es/table";
import { viewDate } from "src/common/formats";
import { ZSchedulingRow, ZSchedulingSettings } from "../../SchedulingTypes";
import styles from "./SchedulingTable.module.less";

const buildDefaultProps = (
  key: keyof ZSchedulingRow,
  title: string,
): ColumnType<ZSchedulingRow> => ({
  key,
  dataIndex: key,
  title,
  className: classNames([styles.cellEllipsis, styles.cellSize]),
});

const buildDefaultDateProps = (
  key: keyof ZSchedulingRow,
  title: string,
): ColumnType<ZSchedulingRow> => ({
  ...buildDefaultProps(key, title),
  render: (value: string | null) => (value ? viewDate(value) : null),
});

const buildExtraColumn = (
  column: ColumnType<ZSchedulingRow>,
  settingsValue: number | null | undefined,
): ColumnType<ZSchedulingRow>[] => (settingsValue ? [column] : []);

export const buildColumns = (
  settings: ZSchedulingSettings,
): TableProps["columns"] => [
  {
    ...buildDefaultProps("name", t("Task group / task")),
    className: classNames([
      styles.cellEllipsis,
      styles.cellSize,
      styles.cellName,
    ]),
    fixed: "left",
    render: (_, row: ZSchedulingRow) => (
      <span
        className={classNames([
          [row.type === "PLAN" || row.type === "GROUP", styles.bold],
        ])}
      >
        {row.name}
        {row.type === "GROUP" &&
          row.children?.length &&
          `: ${row.children?.length}`}
      </span>
    ),
  },
  {
    ...buildDefaultProps("state", t("State")),
    className: styles.cellSize,
    render: (_, row: ZSchedulingRow) => {
      const { state } = row;
      if (!state) return null;
      const parsed = parseInt(state, 10);
      return Number.isNaN(parsed) ? (
        <div className={styles.cellEllipsis}>{state}</div>
      ) : (
        <Progress percent={parsed} strokeColor="#22BA7A" />
      );
    },
  },
  buildDefaultProps("priority", t("Priority")),
  buildDefaultProps("division", t("Division")),
  buildDefaultProps("performer", t("Performer")),
  buildDefaultProps("performerLoad", t("Performer load per task per day")),
  buildDefaultProps("plannedDuration", t("Planned duration")),
  buildDefaultProps("actualDuration", t("Actual duration")),
  ...buildExtraColumn(
    buildDefaultProps("supplier", t("Supplier")),
    settings.taskSettings?.supplierNameAttributeId,
  ),
  buildDefaultDateProps("plannedStartDate", t("Planned start date")),
  buildDefaultDateProps("plannedEndDate", t("Planned end date")),
  buildDefaultDateProps("actualStartDate", t("Actual start date")),
  buildDefaultDateProps("actualEndDate", t("Actual end date")),
  buildDefaultProps("deltaDays", t("Delay / override")),
];

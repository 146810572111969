// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-VersionTab-VersionTab-module__box--p6lma {
  display: grid;
  row-gap: 15px;
  padding: 15px;
}
.src-pages-ManagementPage-VersionTab-VersionTab-module__box--p6lma b {
  font-family: monospace;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/VersionTab/VersionTab.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,aAAA;AACF;AAJA;EAKI,sBAAA;AAEJ","sourcesContent":[".box {\n  display: grid;\n  row-gap: 15px;\n  padding: 15px;\n  b {\n    font-family: monospace;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-ManagementPage-VersionTab-VersionTab-module__box--p6lma`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { SchedulingControlStore } from "../../../SchedulingControlStore";
import { DeletionInfoTable } from "./DeletionInfoTable";

interface PropsDeleteButton {
  store: SchedulingControlStore;
}

export const DeleteButton: React.FC<PropsDeleteButton> = observer(
  ({ store }) => {
    const { selectedRows, taskDeletion, operationLoading } = store;
    return (
      <>
        <Button
          disabled={!selectedRows.length}
          loading={operationLoading}
          onClick={() => store.checkDelete()}
          icon={<DeleteOutlined />}
        >
          {t("Delete selected")}
        </Button>
        <ModalVertFixed
          width={860}
          open={!!taskDeletion}
          onCancel={() => store.setTaskDeletion(null)}
          title={t("Deletion of tasks")}
          onOk={() => store.doDelete()}
          okText={t("Delete")}
          okButtonProps={{ loading: operationLoading }}
        >
          <DeletionInfoTable store={store} />
        </ModalVertFixed>
      </>
    );
  },
);

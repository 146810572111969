import { makeAutoObservable } from "mobx";
import { delay } from "src/common/delay";
import { onError } from "src/common/onError";
import { Cell2, isEqualCellValues } from "./Cell2";
import { isSymbolKey, KeyDef } from "./isSymbolKey";

export class Sheet2Store {
  constructor() {
    makeAutoObservable(this);
  }

  init() {
    setTimeout(() => this.activateFirst(), 10);
  }

  cells: Record<string, Cell2> = {};

  protected setCell(cell: Cell2) {
    this.cells[cell.cellKey] = cell;
  }

  updatedKeys = new Set<string>();

  updateCell(cell: Cell2) {
    this.setCell(cell); // ??? возможно, надо сначала проверять существование. требуется тестирование
    this.updatedKeys.add(cell.cellKey);
  }

  postUpdate() {
    const { updatedKeys } = this;
    const deadKeys = Object.keys(this.cells).filter(
      (key) => !updatedKeys.has(key),
    );
    deadKeys.forEach((key) => {
      delete this.cells[key];
    });
    this.updatedKeys.clear();
  }

  cellByKey(cellKey: string | null): Cell2 | null {
    return cellKey ? this.cells[cellKey] ?? null : null;
  }

  editing = false;

  activeKey: string | null = null;

  get activeCell(): Cell2 | null {
    return this.cellByKey(this.activeKey);
  }

  isViewMode(cellKey: string): boolean {
    return (
      !this.editing ||
      cellKey !== this.activeKey ||
      this.saving.has(cellKey) ||
      !!this.cellByKey(cellKey)?.viewOnly
    );
  }

  activateFirst() {
    this.activate(Object.keys(this.cells)[0] ?? null, false);
  }

  activate(cellKey: string | null, isEdit: boolean) {
    const prevCell = this.cellByKey(this.activeKey);
    if (prevCell?.cellKey === cellKey) {
      if (isEdit && !this.editing) {
        this.editing = !prevCell.viewOnly;
        this.activeCell?.focus(true);
      }
      return;
    }
    this.activeKey = cellKey;
    this.editing = false;
    const cell = this.cellByKey(cellKey);
    if (cell) {
      if (!cell.viewOnly) this.editing = isEdit;
      cell.focus(isEdit);
    }
  }

  stepTo(x: number, y: number): boolean {
    const newCell = Object.values(this.cells).find(
      ({ pos }) => pos.x === x && pos.y === y,
    );
    if (newCell) {
      this.activate(newCell.cellKey, false);
      return true;
    }
    return false;
  }

  findRelative(
    x: number,
    y: number,
    to: "mostLeft" | "mostRight",
  ): Cell2 | null {
    const cells = Object.values(this.cells);
    let dstCell: Cell2 | null = null;
    if (to === "mostLeft") {
      cells.forEach((cell) => {
        const { pos } = cell;
        if (pos.y === y && (!dstCell || pos.x < dstCell.pos.x)) {
          dstCell = cell;
        }
      });
    } else if (to === "mostRight") {
      cells.forEach((cell) => {
        const { pos } = cell;
        if (pos.y === y && (!dstCell || pos.x > dstCell.pos.x)) {
          dstCell = cell;
        }
      });
    }
    return dstCell;
  }

  // -- keys
  onArrow(dx: number, dy: number) {
    if (this.editing) return;
    const { activeCell } = this;
    if (activeCell) {
      this.stepTo(activeCell.pos.x + dx, activeCell.pos.y + dy);
    }
  }

  onSkipToBound(mode: "mostLeft" | "mostRight") {
    if (this.editing) return;
    const { activeCell } = this;
    if (activeCell) {
      const dst = this.findRelative(activeCell.pos.x, activeCell.pos.y, mode);
      if (dst) {
        this.activate(dst.cellKey, false);
      }
    }
  }

  onEnter(dy: -1 | 1 = 1) {
    const { activeCell } = this;
    if (activeCell) {
      // Сейчас требования такие, что при нажатии на Enter всегда переход на ячейку ниже
      if (!this.stepTo(activeCell.pos.x, activeCell.pos.y + dy))
        this.blur(activeCell);
    }
  }

  onDelete() {
    const { activeCell, editing } = this;
    if (!editing && activeCell) {
      this.setCurCellValue(activeCell.cellKey, activeCell.emptyValue);
      this.blur(activeCell);
    }
  }

  onBackspace() {
    this.onDelete();
  }

  onKeyDown(cellKey: string, e: KeyDef) {
    // Чтобы не обрабатывать какие-то случаи (н.р. Enter в TextArea), надо их анализировать снаружи и не вызывать функцию
    const inView = this.isViewMode(cellKey);
    if (inView && isSymbolKey(e)) {
      this.activate(cellKey, true);
      // после активации ячейки нажатый символ передаётся в компонент.
      // Input после фокуса выделяет всё своё содержимое, поэтому новый символ замещает содержимое.
    } else if (e.key === "ArrowDown") {
      this.onArrow(0, 1);
    } else if (e.key === "ArrowUp") {
      this.onArrow(0, -1);
    } else if (e.key === "ArrowLeft") {
      this.onArrow(-1, 0);
    } else if (e.key === "ArrowRight") {
      this.onArrow(1, 0);
    } else if (e.key === "Enter") {
      this.onEnter(e.shiftKey ? -1 : 1);
    } else if (e.key === "Escape") {
      this.resetCurValue(cellKey, true);
    } else if (e.key === "Delete") {
      this.onDelete();
    } else if (e.key === "Backspace") {
      this.onBackspace();
    } else if (e.key === "F2") {
      this.activate(this.activeKey, true);
    } else if (e.key === "Home") {
      this.onSkipToBound("mostLeft");
    } else if (e.key === "End") {
      this.onSkipToBound("mostRight");
    }
  }

  cellSrcValues: Record<string, unknown> = {};

  cellCurValues: Record<string, unknown> = {};

  setSrcCellValue(cellKey: string, value: unknown) {
    this.cellSrcValues[cellKey] = value;
    this.setCurCellValue(cellKey, value);
  }

  setCurCellValue(cellKey: string, value: unknown) {
    this.cellCurValues[cellKey] = value;
  }

  resetCurValue(cellKey: string, stopEdit: boolean) {
    this.setCurCellValue(cellKey, this.cellSrcValues[cellKey]);
    if (stopEdit) {
      this.editing = false;
      this.clearError(cellKey);
    }
  }

  saving = new Set<string>();

  startSaving(cellKey: string) {
    this.saving.add(cellKey);
  }

  stopSaving(cellKey: string) {
    this.saving.delete(cellKey);
  }

  errors: Record<string, string> = {};

  setError(cellKey: string, errMsg: string) {
    this.errors[cellKey] = errMsg;
  }

  clearError(cellKey: string) {
    delete this.errors[cellKey];
  }

  /**
   * Для прямого изменения значений из бизнес-логики
   * @param cellKey
   * @param newValue
   */
  setValueByKey(cellKey: string, newValue: unknown) {
    this.setCurCellValue(cellKey, newValue);
    this.saveCell(this.cellByKey(cellKey)).catch(onError);
  }

  /**
   * Если нужно проделать какую-то операцию с ячейкой
   */
  async cellTask(cellKey: string, task: () => Promise<void>) {
    try {
      this.clearError(cellKey);
      this.startSaving(cellKey);
      await delay(1); // Иногда компонент что-то меняет после потери фокуса. н.р InputNumber контролирует правильность
      await task();
    } catch (e) {
      this.setError(cellKey, e.message);
    } finally {
      this.stopSaving(cellKey);
    }
  }

  async saveCell(cell: Cell2 | null) {
    if (!cell) return;
    const { cellKey: key, validate, save } = cell;
    this.cellTask(key, async () => {
      const srcValue = this.cellSrcValues[key];
      const curValue = this.cellCurValues[key];
      if (isEqualCellValues(curValue, srcValue, cell)) return;
      if (validate) {
        await validate(curValue);
      }
      const guardValue = (this.guard[key] ?? 0) + 1;
      this.guard[key] = guardValue;
      const callback = await save(curValue);
      if (this.guard[key] === guardValue) {
        callback();
      }
    });
  }

  blur(cell: Cell2 | null) {
    this.editing = false;
    this.saveCell(cell).catch(onError);
  }

  guard: Record<string, number> = {};
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlBox--vOaKJ {
  font-size: 14px;
  padding: 4px 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  background: none;
  border: thin solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  line-height: 22px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlBox--vOaKJ:focus {
  border-color: #e43d00;
  outline: none;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlBox--vOaKJ:hover {
  border-color: #e43d00;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlValue--qW6SA {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/PointSelectExt/PointSelectExt.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,QAAA;EACA,gBAAA;EACA,0BAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;AACJ;AAAI;EACI,qBAAA;EACA,aAAA;AAER;AAAI;EACI,qBAAA;AAER;AACA;EACI,OAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".controlBox {\n    font-size: 14px;\n    padding: 4px 11px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    cursor: pointer;\n    gap: 4px;\n    background: none;\n    border: thin solid rgb(217,217,217);\n    border-radius: 4px;\n    width: 100%;\n    line-height: 22px;\n    &:focus {\n        border-color: #e43d00;\n        outline: none;\n    }\n    &:hover {\n        border-color: #e43d00;\n    }\n}\n.controlValue {\n    flex: 1;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlBox--vOaKJ`,
	"controlValue": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlValue--qW6SA`
};
export default ___CSS_LOADER_EXPORT___;

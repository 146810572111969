import { makeAutoObservable } from "mobx";
import { ZAttrViewInfo } from "src/common/attrView";
import { onError } from "src/common/onError";
import {
  EntityFiltersPageStore,
  saveLoadParams,
} from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import {
  loadObjectAttrinbutesReduced,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectRefSelector } from "src/common/attrEdit/components";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { loadFilterAttributes } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectFilter/loadFilterAttributes";
import { FormInstance } from "antd";
import { DefaultTSettingsStore } from "src/pages/EntityFiltersPage/DefaultTSettingsStore";
import { TableSettingsType } from "src/pages/ManagementPage/Obj2Tab/ObjTableSettings/ObjTableSettingsTypes";
import { selectOnRowClick } from "../tables/selectOnRowClick";

export class ObjectRefSelectorStore {
  filtersPageStore: EntityFiltersPageStore | null = null;

  attrInfo: ZAttribute | null = null;

  setAttrInfo(data: ZAttribute) {
    this.attrInfo = data;
  }

  component: ZObjectRefSelector | null = null;

  setComponent(data: ZObjectRefSelector) {
    this.component = data;
  }

  viewInfo: ZAttrViewInfo | null = null;

  setViewInfo(data: ZAttrViewInfo) {
    this.viewInfo = data ?? null;
  }

  initialValues: string[] | null = null;

  setInitialValues(list: string[]) {
    this.initialValues = list;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  attsMetaMap: Record<number, ZAttribute> = {};

  setAttsMetaMap(map: Record<number, ZAttribute>) {
    this.attsMetaMap = map;
  }

  get labelAtts() {
    return this.component?.labelAtts;
  }

  values: string[] = [];

  setValues(list: string[]) {
    this.values = list;
  }

  filter: ZAttribute | null = null;

  setFilter(newFilter: ZAttribute | null) {
    this.filter = newFilter;
  }

  async init(
    attrInfo: ZAttribute,
    component: ZObjectRefSelector,
    viewInfo: ZAttrViewInfo,
    multiple: boolean,
    value?: string[],
  ) {
    try {
      this.setLoading(true);
      const { filter, referenceId, id: attrId } = attrInfo;
      const safeValues = value || [];
      const objectId = Number(referenceId);
      const attsMetaMap = makeObjectAttributesMap(
        await loadObjectAttrinbutesReduced(String(objectId)),
      );

      this.setAttrInfo(attrInfo);
      this.setComponent(component);
      this.setViewInfo(viewInfo);
      this.setInitialValues(safeValues);
      this.setAttsMetaMap(attsMetaMap);

      if (filter) {
        const filterAttr = (await loadFilterAttributes(attrId)).find(
          ({ id }) => id === filter,
        );
        this.setFilter(filterAttr ?? null);
      }

      const actions = new Set([
        EntFilterActionType.changeState,
        EntFilterActionType.export,
      ]);
      if (component.isCreateButtonAvailable) {
        actions.add(EntFilterActionType.create);
      }
      this.filtersPageStore = new EntityFiltersPageStore({
        objectId,
        selectionSettings: {
          keepSelected: true,
          selectionType: multiple ? "checkbox" : "radio",
        },
        onLoad: () => {
          this.syncSelected(this.values);
          // Нужно пересохранить значения без ограничителей, чтобы на странице списка экземпляров они не фигурировали
          if (filter) this.saveSafeParams(objectId);
        },
        actions,
        onRowClick: (row) => {
          const store = this.filtersPageStore?.tableStore;
          if (store) selectOnRowClick(row, store);
        },
        defTSettingsStore: new DefaultTSettingsStore(
          TableSettingsType.valueObjectId,
        ),
      });
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  syncSelected(values: string[]) {
    const tableStore = this.filtersPageStore?.tableStore;
    if (!tableStore) return;
    const allRows = tableStore.rows;
    const needSelectKeys = new Set(values.map(Number));
    const needSelectRows = allRows.filter((row) => needSelectKeys.has(row.id));
    tableStore?.safeSelect(needSelectRows);
  }

  get isLoading(): boolean {
    const filterLoading =
      this.filter &&
      (this.filtersPageStore?.loading ||
        this.filtersPageStore?.tableStore?.loading);
    return filterLoading || this.loading;
  }

  addFilters(form: FormInstance) {
    const { filter, filtersPageStore } = this;
    if (!filter || !filtersPageStore) return;
    const { id } = filter;
    const { tableStore } = filtersPageStore;
    if (!tableStore) return;
    const currentFilters = tableStore.filters;
    if (!currentFilters) return;

    const filterValue: string[] | null | undefined = form.getFieldValue(
      String(id),
    );
    tableStore.setFilters({
      ...currentFilters,
      attributeId: filterValue?.length ? id : undefined,
      restrictionValues: filterValue?.length ? filterValue : undefined,
    });
  }

  saveSafeParams(objectId: number) {
    const params = this.filtersPageStore?.tableStore?.params;
    if (!params) return;
    saveLoadParams(
      {
        ...params,
        filters: {
          ...params.filters,
          attributeId: undefined,
          restrictionValues: undefined,
        },
      },
      objectId,
    );
  }

  constructor() {
    makeAutoObservable(this);
  }
}

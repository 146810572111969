export type Cell2Validator = (value: unknown) => Promise<void>;

export interface Cell2 {
  cellKey: string;
  pos: { x: number; y: number };
  focus(select: boolean): void;
  blur?(): void;
  save: (value: unknown) => Promise<() => void>;
  validate?: Cell2Validator;
  readonly emptyValue: unknown;
  equals?: (a: unknown, b: unknown) => boolean;
  viewOnly?: boolean; // для случаев, где нет режима редактирования. Н.р. кнопка, по нажатию которой открывается модалка.
}

export const isEqualCellValues = (a: unknown, b: unknown, c: Cell2): boolean =>
  c.equals?.(a, b) ?? a === b;

import * as React from "react";
import { Select, SelectProps } from "antd";
import type { BaseSelectRef } from "rc-select";
import { observer } from "mobx-react-lite";
import { CellInternalBox } from "./CellInternalBox";
import { PropsCellEditor } from "./PropsCell";

export const CellSelect: React.FC<PropsCellEditor<SelectProps>> = observer(
  ({ cell, editorProps }) => {
    const { cellKey, emptyValue } = cell;
    const { store, value, ...cellProps } = cell;
    const iref = React.useRef<BaseSelectRef>(null);
    React.useEffect(() => {
      store.setCell({
        ...cellProps,
        focus() {
          iref.current?.focus();
        },
        blur() {
          iref.current?.blur();
        },
      });
    }, []);
    React.useEffect(() => {
      store.setSrcCellValue(cellKey, value);
    }, [value]);
    const inView = store.isViewMode(cellKey);
    const errMsg = store.errors[cellKey];
    return (
      <CellInternalBox store={store} cellKey={cellKey}>
        <Select
          ref={iref}
          {...editorProps}
          style={{ width: "100%" }}
          status={errMsg ? "error" : undefined}
          value={store.cellCurValues[cellKey]}
          onChange={(newValue) => {
            store.setCurCellValue(
              cellKey,
              newValue === undefined ? emptyValue : newValue,
            );
          }}
          open={!inView}
          onFocus={() => {
            store.activate(cellKey, false);
          }}
          onBlur={() => {
            store.blur(store.cellByKey(cellKey));
          }}
          onClick={() => {
            if (store.activeKey !== cellKey || inView) {
              store.activate(cellKey, true);
            } else {
              store.blur(store.activeCell);
            }
          }}
          onSelect={() => {
            store.blur(store.activeCell);
          }}
          onKeyDown={(e) => {
            if (inView) {
              store.onKeyDown(cellKey, e);
            } else if (e.key === "Enter") {
              store.blur(store.activeCell);
            } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
              store.resetCurValue(cellKey, true);
              store.onKeyDown(cellKey, e);
            } else if (e.key === "Escape") {
              store.resetCurValue(cellKey, true);
            }
          }}
        />
      </CellInternalBox>
    );
  },
);

import * as React from "react";
import { applyOrderStd, CtrlColumns } from "src/components/tables/TableStore";
import { Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import { DragableIconStd } from "src/common/icons";
import { SortableList, SortableItemDef } from "src/components/SortableList";
import styles from "./ColumnSettingsList.module.less";

interface PropsColumnSettingsList {
  store: CtrlColumns;
}

export const ColumnSettingsList: React.FC<PropsColumnSettingsList> = observer(
  ({ store }) => {
    const sortedColumns = applyOrderStd(store, store.columns);
    const items: SortableItemDef[] = sortedColumns
      .filter(({ condition }) => !condition || condition())
      .filter(
        ({ title, visibility }) =>
          !(
            visibility === "alwaysVisible" &&
            typeof title === "string" &&
            title.trim() === ""
          ),
      )
      .map((col) => ({
        key: col.key,
        content: (
          <label key={col.key} className={styles.columnInfo}>
            <div className={styles.checkBoxWrapper}>
              <Checkbox
                checked={store.isColumnVisible(col.key)}
                onChange={(e) =>
                  store.setColumnVisible(col.key, e.target.checked)
                }
                disabled={!store.canColumnHide(col.key)}
              />
              <span>{col.title}</span>
            </div>
            <DragableIconStd />
          </label>
        ),
      }));

    const handleSortableChange = (newItems: SortableItemDef[]) => {
      const keys = newItems.map((item) => String(item.key));
      store.setColumnOrder(keys);
    };

    return (
      <SortableList
        containerClassName={styles.container}
        items={items}
        onChange={handleSortableChange}
      />
    );
  },
);

import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, DatePickerProps } from "antd";
import { observer } from "mobx-react-lite";
import { delay } from "src/common/delay";
import { CommonPickerMethods } from "antd/lib/date-picker/generatePicker/interface";
import { onError } from "src/common/onError";
import { CellInternalBox } from "./CellInternalBox";
import { PropsCellEditor } from "./PropsCell";

type PropsCellDatePicker = PropsCellEditor<DatePickerProps> & {
  dataFormat?: string; // "YYYY-MM-DD"
};

export const CellDatePicker: React.FC<PropsCellDatePicker> = observer(
  ({ cell, editorProps, dataFormat = "YYYY-MM-DD" }) => {
    const { cellKey } = cell;
    const { store, value, ...cellProps } = cell;
    const bin2str = (bin: Dayjs | null): string | null =>
      bin?.format(dataFormat) ?? null;
    const iref = React.useRef<CommonPickerMethods>(null);
    React.useEffect(() => {
      store.setCell({
        ...cellProps,
        focus() {
          iref.current?.focus();
          // Нет документированного способа для выделения содержимого
          // Но в случае необходимости можно найти в input в iref.current?.nativeElement
          // console.log(">> native:", iref.current?.nativeElement);
        },
        blur() {
          iref.current?.blur();
        },
      });
    }, []);
    // Компонент работает с датами в виде ISO-строк.
    // А так как антовый DatePicker работает с DayJs, то конвертация происходит на лету.
    const [binValue, setBinValue] = React.useState<Dayjs | null>(null);
    React.useEffect(() => {
      const bv = value && typeof value === "string" ? dayjs(value) : null;
      setBinValue(bv);
      store.setSrcCellValue(cellKey, bin2str(bv));
    }, [value]);
    const inView = store.isViewMode(cellKey);
    const errMsg = store.errors[cellKey];
    return (
      <CellInternalBox store={store} cellKey={cellKey}>
        <DatePicker
          // @ts-ignore
          ref={iref}
          {...editorProps}
          inputReadOnly={inView}
          // readOnly={inView} В версии 5.23.2, но пока 5.13.3
          style={{ width: "100%" }}
          status={errMsg ? "error" : undefined}
          value={binValue}
          open={!inView}
          onChange={(v) => {
            setBinValue(v);
            store.setCurCellValue(cellKey, bin2str(v));
          }}
          onBlur={() => {
            store.blur(store.cellByKey(cellKey));
          }}
          onKeyDown={(e) => {
            store.onKeyDown(cellKey, e);
          }}
          onOpenChange={(open) => {
            if (!open)
              delay(1)
                .then(() => {
                  iref.current?.focus();
                })
                .catch(onError);
          }}
        />
      </CellInternalBox>
    );
  },
);

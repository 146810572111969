// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityHistory-EntityHistory-module__frame--AtG_D {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-pages-EntityCardPage-EntityHistory-EntityHistory-module__mainPart--_XZvi {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  gap: 24px;
  padding: 16px 24px;
  background: #f5f5f5;
  height: 100%;
}
.src-pages-EntityCardPage-EntityHistory-EntityHistory-module__header--vnGvC {
  padding: 16px 24px 20px 24px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.src-pages-EntityCardPage-EntityHistory-EntityHistory-module__title--Q8dAh {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityHistory/EntityHistory.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACF;AAEA;EACE,aAAA;EACA,qCAAA;EACA,gBAAA;EACA,SAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAAF;AAEA;EACE,4BAAA;EACA,gBAAA;EACA,4CAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AAAF;AAEA;EACE,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAF","sourcesContent":[".frame {\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.mainPart {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  overflow: hidden;\n  gap: 24px;\n  padding: 16px 24px;\n  background: #f5f5f5;\n  height: 100%;\n}\n.header {\n  padding: 16px 24px 20px 24px;\n  background: #fff;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.06);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n}\n.title {\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 120%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": `src-pages-EntityCardPage-EntityHistory-EntityHistory-module__frame--AtG_D`,
	"mainPart": `src-pages-EntityCardPage-EntityHistory-EntityHistory-module__mainPart--_XZvi`,
	"header": `src-pages-EntityCardPage-EntityHistory-EntityHistory-module__header--vnGvC`,
	"title": `src-pages-EntityCardPage-EntityHistory-EntityHistory-module__title--Q8dAh`
};
export default ___CSS_LOADER_EXPORT___;

import React, { createRef, RefObject, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { TableSelectionCheckbox } from "src/pages/EntityFiltersPage/AsyncTableView";
import { ResizerStd } from "src/pages/EntityFiltersPage/AsyncTableView/ResizerStd";
import { getColumnWidthStyles } from "src/pages/EntityFiltersPage/AsyncTableView/templates/getColumnWidths";
import styles from "./TableSettingsHeader.module.less";
import { ObjTableControlStore } from "../ObjTableControlStore";

export type PropsTableSettingsHeader = {
  store: ObjTableControlStore;
  useSelection?: boolean;
};

export const TableSettingsHeader = observer(
  ({ store, useSelection }: PropsTableSettingsHeader) => {
    const { tableStore } = store;
    if (!tableStore) return null;
    const { finalColumns, columns, rows, selectionSettings, columnWidths } =
      tableStore;
    const columnRefs = useMemo(
      () =>
        finalColumns.reduce(
          (acc, { key }) => ({ ...acc, [key]: createRef<HTMLDivElement>() }),
          {} as Record<string, RefObject<HTMLDivElement>>,
        ),
      [columns],
    );

    return (
      <div className={styles.tableHeader}>
        {useSelection && rows.length > 0 && (
          <div
            className={classNames([
              styles.headerItem,
              styles.headerItemSelection,
            ])}
          >
            {selectionSettings.selectionType === "checkbox" ? (
              <TableSelectionCheckbox store={tableStore} main />
            ) : (
              <div />
            )}
          </div>
        )}
        {finalColumns.map((column) => (
          <div
            ref={columnRefs[column.key]}
            className={styles.headerItem}
            key={column.key}
            style={getColumnWidthStyles(columnWidths, column.key)}
          >
            <div className={styles.columnHeaderContent}>{column.title}</div>
            {!store.isDisabled && (
              <ResizerStd
                column={column}
                store={tableStore}
                containerRef={columnRefs[column.key]}
                onDragEnd={() => store.saveSettings()}
              />
            )}
          </div>
        ))}
      </div>
    );
  },
);

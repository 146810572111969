import * as React from "react";
import { Input, InputRef } from "antd";
import { observer } from "mobx-react-lite";
import { InputProps } from "antd/lib";
import { CellInternalBox } from "./CellInternalBox";
import { PropsCell2Editor } from "../PropsCell2";

export const Cell2Input: React.FC<PropsCell2Editor<InputProps>> = observer(
  ({ cell, editorProps }) => {
    const { store, value, ...cellProps } = cell;
    const iref = React.useRef<InputRef>(null);
    const { cellKey } = cell;

    React.useEffect(() => {
      store.updateCell({
        ...cellProps,
        focus(select: boolean) {
          iref.current?.focus();
          if (select) iref.current?.select();
        },
        blur() {
          iref.current?.blur();
        },
      });
    });

    React.useEffect(() => {
      store.setSrcCellValue(cellKey, value);
    }, [value]);
    const inView = store.isViewMode(cellKey);
    const errMsg = store.errors[cellKey];
    return (
      <CellInternalBox store={store} cellKey={cellKey}>
        <Input
          ref={iref}
          {...editorProps}
          status={errMsg ? "error" : undefined}
          value={String(store.cellCurValues[cellKey] ?? "")}
          onChange={(e) =>
            store.setCurCellValue(cellKey, e.currentTarget.value)
          }
          style={{ width: "100%" }}
          readOnly={inView}
          onFocus={() => {
            store.activate(cellKey, false);
          }}
          onBlur={() => {
            store.blur(store.cellByKey(cellKey));
          }}
          onClick={() => {
            store.activate(cellKey, false);
          }}
          onDoubleClick={() => {
            store.activate(cellKey, true);
          }}
          onKeyDown={(e) => store.onKeyDown(cellKey, e)}
        />
      </CellInternalBox>
    );
  },
);

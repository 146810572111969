// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__tableHeader--tVXBH {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  position: sticky;
  top: 0;
  background-color: #fafafa;
  z-index: 2;
  width: 100%;
  min-width: fit-content;
}
.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__headerItem--lXJ0m {
  position: relative;
  flex: 1;
  padding: 16px 24px;
  text-align: left;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  color: #767c84;
  display: flex;
  align-items: center;
  min-width: 150px;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
}
.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__headerItem--lXJ0m:first-child {
  border-left: none;
}
.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__headerItemSelection--r3blT {
  flex: none;
  min-width: unset;
  width: 75px;
  display: flex;
  justify-content: center;
}
.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__headerItemSelection--r3blT > * {
  width: 16px;
  height: 16px;
}
.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__columnHeaderContent--iUvWt {
  flex-grow: 1;
  user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/ObjTableSettings/ObjTableControl/TableSettingsHeader/TableSettingsHeader.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,gCAAA;EACA,gBAAA;EACA,MAAA;EACA,yBAAA;EACA,UAAA;EACA,WAAA;EACA,sBAAA;AACF;AAEA;EACE,kBAAA;EAEA,OAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EAEA,8BAAA;EACA,+BAAA;AAFF;AAIE;EACE,iBAAA;AAFJ;AAMA;EACE,UAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AAJF;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;AAOA;EACE,YAAA;EACA,iBAAA;AALF","sourcesContent":[".tableHeader {\n  display: flex;\n  flex-direction: row;\n  border-top: 1px solid #e2e2e2;\n  border-bottom: 1px solid #e2e2e2;\n  position: sticky;\n  top: 0;\n  background-color: #fafafa;\n  z-index: 2;\n  width: 100%;\n  min-width: fit-content;\n}\n\n.headerItem {\n  position: relative;\n\n  flex: 1;\n  padding: 16px 24px;\n  text-align: left;\n  overflow: hidden;\n  font-size: 12px;\n  font-weight: 400;\n  color: #767c84;\n  display: flex;\n  align-items: center;\n  min-width: 150px;\n\n  border-left: 1px solid #e2e2e2;\n  border-right: 1px solid #e2e2e2;\n\n  &:first-child {\n    border-left: none;\n  }\n}\n\n.headerItemSelection {\n  flex: none;\n  min-width: unset;\n  width: 75px;\n  display: flex;\n  justify-content: center;\n  & > * {\n    width: 16px;\n    height: 16px;\n  }\n}\n\n.columnHeaderContent {\n  flex-grow: 1;\n  user-select: none; // Предотвращает выделение текста при перетаскивании\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeader": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__tableHeader--tVXBH`,
	"headerItem": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__headerItem--lXJ0m`,
	"headerItemSelection": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__headerItemSelection--r3blT`,
	"columnHeaderContent": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-TableSettingsHeader-TableSettingsHeader-module__columnHeaderContent--iUvWt`
};
export default ___CSS_LOADER_EXPORT___;

import { z } from "zod";

export const zDemo1Row = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  mass: z.number().nullish(),
  date: z.string().nullish(),
  comment: z.string().nullish(),
  status: z.number().nullish(), // Справочник. Значения соответствуют id
});
export type ZDemo1Row = z.infer<typeof zDemo1Row>;

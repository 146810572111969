import React from "react";
import * as icons from "@ant-design/icons/lib/icons";

interface PropsIconRenderer {
  iconKey: string;
}

export const IconRenderer: React.FC<PropsIconRenderer> = ({ iconKey }) => {
  const IconComponent = icons[iconKey as keyof typeof icons];
  if (!IconComponent) return null;
  return <IconComponent />;
};

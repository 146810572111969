import React from "react";
import { Table } from "antd";
import { observer } from "mobx-react-lite";
import { SchedulingControlStore } from "../../../../SchedulingControlStore";
import { buildDelTableColumns } from "./buildDelTableColumns";

interface PropsDeletionInfoTable {
  store: SchedulingControlStore;
}

export const DeletionInfoTable: React.FC<PropsDeletionInfoTable> = observer(
  ({ store }) => {
    const { delTableData } = store;
    const columns = buildDelTableColumns();
    return (
      <Table
        columns={columns}
        dataSource={delTableData}
        rowKey="id"
        pagination={false}
      />
    );
  },
);

import React from "react";
import { Button, ButtonProps } from "antd";
import { t } from "i18next";
import styles from "./DefaultViewButton.module.less";

type PropsDefaultViewButton = Omit<
  ButtonProps,
  "children" | "className" | "type"
>;
export const DefaultViewButton: React.FC<PropsDefaultViewButton> = (props) => (
  <Button {...props} className={styles.settingsButton} type="link">
    {t("Set default view")}
  </Button>
);

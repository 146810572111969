import * as React from "react";
import { CtrlColumns } from "src/components/tables/TableStore";
import { Dropdown } from "antd";
import { observer } from "mobx-react-lite";
import { SettingsIconStd } from "src/common/icons";
import { useClickOutside } from "src/common/useClickOutside";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./ColumnsSettings.module.less";
import { ColumnSettingsList } from "./ColumnSettingsList";

interface PropsColumnsSettings {
  store: CtrlColumns;
  extraButtons?: React.ReactNode;
}

export const ColumnsSettings: React.FC<PropsColumnsSettings> = observer(
  ({ store, extraButtons }: PropsColumnsSettings) => {
    const [open, setOpen] = React.useState(false);
    const ref = useClickOutside(() => {
      setOpen(false);
    });

    return (
      <Dropdown
        menu={{ className: styles.menu }}
        dropdownRender={() => (
          <div ref={ref} className={styles.sortableContainer}>
            <div className={styles.sortableList}>
              <ColumnSettingsList store={store} />
            </div>
            {extraButtons && (
              <div className={styles.extraButtons}>{extraButtons}</div>
            )}
          </div>
        )}
        open={open}
        placement="bottomRight"
      >
        <div className={styles.trigger}>
          {open ? (
            <CloseOutlined onClick={() => setOpen(false)} />
          ) : (
            <div role="presentation" onClick={() => setOpen(true)}>
              <SettingsIconStd />
            </div>
          )}
        </div>
      </Dropdown>
    );
  },
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__attTitle--vjVtH {
  font-weight: 700;
}
.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__attEmpty--njthb {
  color: #0000004d;
}
.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__timestamp--V6TLu,
.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__fio--QqK_l {
  color: #0000008c;
}
.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__fio--QqK_l {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__timelineChild--PklTS {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__changeInfo--v8Fqs {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityHistory/EntityAttsHistory/EntityAttsHistory.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAGA;;EAEE,gBAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAFF;AAKA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAHF;AAMA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAJF","sourcesContent":[".attTitle {\n  font-weight: 700;\n}\n\n.attEmpty {\n  color: #0000004d;\n}\n\n.timestamp,\n.fio {\n  color: #0000008c;\n}\n\n.fio {\n  display: flex;\n  align-items: center;\n  column-gap: 5px;\n}\n\n.timelineChild {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n\n.changeInfo {\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attTitle": `src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__attTitle--vjVtH`,
	"attEmpty": `src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__attEmpty--njthb`,
	"timestamp": `src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__timestamp--V6TLu`,
	"fio": `src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__fio--QqK_l`,
	"timelineChild": `src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__timelineChild--PklTS`,
	"changeInfo": `src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-EntityAttsHistory-module__changeInfo--v8Fqs`
};
export default ___CSS_LOADER_EXPORT___;

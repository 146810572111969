import { delay } from "src/common/delay";
import { zDemo1Row, ZDemo1Row } from "./ZDemo1Data";

/* eslint no-plusplus: "off"  */

const key = "data1";

export const loadDemo1 = async (): Promise<ZDemo1Row[]> => {
  await delay(3000);
  const text = window.localStorage.getItem(key);
  if (!text) {
    const res: ZDemo1Row[] = [];
    for (let y = 0; y < 3; y++) {
      res.push({
        id: y + 1,
        name: `name ${y + 1}`,
        email: "",
        comment: "",
      });
    }
    window.localStorage.setItem(key, JSON.stringify(res));
    return res;
  }
  const json = JSON.parse(text);
  return zDemo1Row.array().parse(json);
};

export const saveCell1 = async (
  rowId: number,
  field: keyof ZDemo1Row,
  value: unknown,
): Promise<void> => {
  await delay(3000);
  if (value === "error") {
    throw Error("Серверная проверка отклонила значение: error");
  }
  const text = window.localStorage.getItem(key);
  if (!text) throw Error(`Invalid data in local storage`);
  const json = JSON.parse(text);
  const rows = zDemo1Row.array().parse(json);
  const dstRow = rows.find(({ id }) => id === rowId);
  if (!dstRow) throw Error(`Row #${rowId} not found`);
  // @ts-ignore
  dstRow[field] = value;
  zDemo1Row.parse(dstRow); // Возможно, что пришло какое-то неправильное значение
  window.localStorage.setItem(key, JSON.stringify(rows));
};

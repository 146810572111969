import * as React from "react";
import { loadFileInfo } from "src/common/apiFiles";
import { onError } from "src/common/onError";
import { Spin } from "antd";
import { AttrData } from "../AttributeHistrory.types";

interface FileItem {
  name: string;
  uid: string;
}

export const AttrFile: React.FC<AttrData> = ({ value }: { value: string }) => {
  const [items, setItems] = React.useState<FileItem[]>([]);
  const [loading, setLoading] = React.useState(false);

  const init = async () => {
    try {
      setLoading(true);
      const result = await Promise.all(
        value.split(",").map(async (uid) => {
          const fileResp = await loadFileInfo(uid);
          return { fileResp, uid };
        }),
      );
      setItems(
        result.map(({ fileResp, uid }) => ({
          name: fileResp.fileName,
          uid,
        })),
      );
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return loading ? (
    <Spin spinning={loading}>...</Spin>
  ) : (
    <span>{items.map(({ name }) => name).join("; ")}</span>
  );
};

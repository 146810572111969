import * as React from "react";
import { InputNumber } from "antd";
import { observer } from "mobx-react-lite";
import { InputNumberProps } from "antd/lib/input-number";
import { CellInternalBox } from "./CellInternalBox";
import { PropsCellEditor } from "./PropsCell";

export const CellNumber: React.FC<PropsCellEditor<InputNumberProps>> = observer(
  ({ cell, editorProps }) => {
    const { cellKey } = cell;
    const { store, value, ...cellProps } = cell;
    const iref = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
      store.setCell({
        ...cellProps,
        focus(select) {
          iref.current?.focus();
          if (select) iref.current?.select();
        },
        blur() {
          iref.current?.blur();
        },
      });
    }, []);
    React.useEffect(() => {
      store.setSrcCellValue(cellKey, value);
    }, [value]);
    const isReadonly = store.isViewMode(cellKey);
    const errMsg = store.errors[cellKey];
    return (
      <CellInternalBox store={store} cellKey={cellKey}>
        <InputNumber
          ref={iref}
          {...editorProps}
          keyboard={false}
          status={errMsg ? "error" : undefined}
          value={store.cellCurValues[cellKey] as number}
          onChange={(newValue) => {
            store.setCurCellValue(cellKey, newValue);
          }}
          style={{ width: "100%" }}
          readOnly={isReadonly}
          onFocus={() => {
            store.activate(cellKey, false);
          }}
          onBlur={() => {
            store.blur(store.cellByKey(cellKey));
          }}
          onClick={() => {
            store.activate(cellKey, false);
          }}
          onDoubleClick={() => {
            store.activate(cellKey, true);
          }}
          onKeyDown={(e) => {
            store.onKeyDown(cellKey, e);
          }}
        />
      </CellInternalBox>
    );
  },
);

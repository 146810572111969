import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { ZAttribute } from "src/types/ZAttribute";
import { RemoteData } from "src/common/RemoteData";
import { ObjTableControlStore } from "./ObjTableControl/ObjTableControlStore";
// import { loadObjTableSettings } from "./apiTableSettings";
import {
  TableSettingsType,
  ZObjTableSettings,
  ZObjTableSettingsList,
} from "./ObjTableSettingsTypes";
import {
  loadObjTableSettings,
  loadSortableAttributes,
  createObjTableSettings,
  updateObjTableSettings,
} from "./apiTableSettings";

export class ObjTableSettingsStore {
  constructor() {
    makeAutoObservable(this);
  }

  initialSettings: RemoteData<ZObjTableSettingsList> = { status: "none" };

  setInitialSettings(settings: RemoteData<ZObjTableSettingsList>) {
    this.initialSettings = settings;
  }

  loading: boolean = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  objectId: number | null = null;

  setObjectId(id: number) {
    this.objectId = id;
  }

  isUniformly: boolean = false;

  setIsUniformly(flag: boolean) {
    this.isUniformly = flag;
  }

  sortableAttrs: ZAttribute[] = [];

  setSortableAttrs(attrs: ZAttribute[]) {
    this.sortableAttrs = attrs;
  }

  isNew: boolean = false;

  setIsNew(flag: boolean) {
    this.isNew = flag;
  }

  sortingStores: Map<string, ObjTableControlStore> = new Map();

  async init(objectId: number) {
    try {
      this.setInitialSettings({ status: "wait" });
      this.setObjectId(objectId);
      const [settings, sortableAttrs] = await Promise.all([
        loadObjTableSettings(objectId),
        loadSortableAttributes(objectId),
      ]);
      this.setSortableAttrs(sortableAttrs);
      const tables: ZObjTableSettings[] = settings.tables ?? [];
      this.setIsNew(!tables.length);
      if (!tables.length) {
        Object.values(TableSettingsType).forEach((value) =>
          tables.push({
            type: value,
            sort: null,
            displayAttributes: [],
          }),
        );
      }

      tables.forEach((setting, index) => {
        this.sortingStores.set(
          setting.type,
          new ObjTableControlStore(
            () => this.loading,
            (flag) => this.setLoading(flag),
            async () =>
              this.isUniformly && index === 0
                ? this.syncSettings()
                : this.save(),
            () =>
              (this.isUniformly && index !== 0) ||
              this.loading ||
              this.initialSettings.status !== "ready",
            sortableAttrs,
          ),
        );
      });
      this.setInitialSettings({
        status: "ready",
        result: { ...settings, tables },
      });
      this.setIsUniformly(settings.isUniformly);
    } catch (error) {
      this.setInitialSettings({ status: "error", error });
    }
  }

  async save() {
    try {
      const { sortingStoresList, objectId, isUniformly, isNew } = this;
      if (!objectId) return;
      this.setLoading(true);
      const result: ZObjTableSettingsList = {
        isUniformly,
        tables: [],
      };
      sortingStoresList.forEach((store) => {
        const { settings } = store;
        if (settings) result.tables?.push(settings);
      });
      if (isNew) {
        await createObjTableSettings(objectId, result);
        this.setIsNew(false);
      } else {
        await updateObjTableSettings(objectId, result);
      }
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async syncSettings() {
    try {
      const { sortingStoresList } = this;
      const refSettingsStore = sortingStoresList[0];
      const refColumns = refSettingsStore?.settings?.displayAttributes;
      const refParams = refSettingsStore?.tableStore?.params;
      if (!refColumns || !refParams) return;
      this.setIsUniformly(true);
      sortingStoresList.slice(1).forEach((store) => {
        store.setTableSettings(refColumns, refParams);
      });
      await this.save();
    } catch (error) {
      onError(error);
    }
  }

  get sortingStoresList(): ObjTableControlStore[] {
    return Array.from(this.sortingStores.values());
  }
}

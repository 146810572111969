import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  AsyncTableView,
  ATVTemplateToken,
} from "src/pages/EntityFiltersPage/AsyncTableView";
import {
  ZEntityRow,
  EntityFilters,
} from "src/pages/EntityFiltersPage/EntityList/types";
import { Flex, Spin } from "antd";
import { ObjTableControlStore } from "./ObjTableControlStore";
import styles from "./ObjTableControl.module.less";
import { ColumnsModal, SortSelect } from "../controls";
import { titleSettingsMap } from "./titleSettingMap";
import { ZObjTableSettings } from "../ObjTableSettingsTypes";
import { TableSettingsHeader } from "./TableSettingsHeader";
import { SortOrderSelect } from "../controls/SortOrderSelect";

interface PropsObjTableControl {
  objectId: number;
  initialSettings: ZObjTableSettings;
  store: ObjTableControlStore;
}

export const ObjTableControl: React.FC<PropsObjTableControl> = observer(
  ({ objectId, initialSettings, store }) => {
    const { tableStore, isLoading } = store;
    useEffect(() => {
      store.init(objectId, initialSettings);
    }, [objectId, initialSettings]);
    const token: ATVTemplateToken = {
      header() {
        return <TableSettingsHeader store={store} useSelection />;
      },
    };
    if (!tableStore && isLoading) return <Spin spinning />;

    return (
      tableStore && (
        <Flex vertical gap={12}>
          <div className={styles.title}>
            {titleSettingsMap[initialSettings.type]}
          </div>
          <Flex justify="space-between" align="center">
            <div className={styles.sortBlock}>
              <SortSelect store={store} />
              <SortOrderSelect store={store} />
            </div>
            <ColumnsModal store={store} />
          </Flex>
          <div className={styles.tableContainer}>
            <AsyncTableView<ZEntityRow, EntityFilters>
              store={tableStore}
              columns={tableStore.columns}
              useHeader
              useSelection
              templateToken={token}
            />
          </div>
        </Flex>
      )
    );
  },
);

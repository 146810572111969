import { TreeLikeData } from "src/types/TreeLikeData";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrTypeName } from "src/types/AttrType";
import { viewDate } from "src/common/formats";
import dayjs from "dayjs";
import { EntityFilterTreeData, ZEntityFilterValue } from "./types";

const keyReg = new Map<number, number>();
const getKey = (attrId: number) => {
  const makeKey = (key: number) => `${attrId}_${key}`;
  if (keyReg.has(attrId)) {
    const count = Number(keyReg.get(attrId)) + 1;
    keyReg.set(attrId, count);
    return makeKey(count);
  }
  keyReg.set(attrId, 1);
  return makeKey(1);
};

export const attr2TreeData = (
  { id, valueType }: ZAttribute,
  filterValue: ZEntityFilterValue,
  isLeaf: boolean,
  typesMap: Record<number, string>,
): TreeLikeData<EntityFilterTreeData> => ({
  key: getKey(id),
  title: transformTitleByType(filterValue.name, valueType, typesMap),
  attrId: id,
  filterValue,
  isLeaf,
});

const titleTransformers: Partial<
  Record<AttrTypeName, (title: string) => string>
> = {
  [AttrTypeName.date]: (title) =>
    dayjs(title).isValid() ? viewDate(title) : title,
};

const transformTitleByType = (
  title: string,
  valueType: number,
  typesMap: Record<number, string>,
): string =>
  titleTransformers[typesMap[valueType] as AttrTypeName]?.(title) ?? title;

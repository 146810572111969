import { observer } from "mobx-react-lite";
import React from "react";
import { Space, Switch } from "antd";
import { ObjTableSettingsStore } from "../ObjTableSettingsStore";

interface PropsUniformlySwitch {
  store: ObjTableSettingsStore;
}

export const UniformlySwitch: React.FC<PropsUniformlySwitch> = observer(
  ({ store }) => {
    const { isUniformly, loading } = store;
    const onChange = (checked: boolean) => {
      store.setIsUniformly(checked);
      if (checked) {
        store.syncSettings();
      } else {
        store.save();
      }
    };
    return (
      <Space>
        <Switch
          value={isUniformly ?? undefined}
          onChange={onChange}
          disabled={loading}
        />
        <div>Установить вид для всех списков экземпляров</div>
      </Space>
    );
  },
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableSettings-module__box--Inl4q {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/ObjTableSettings/ObjTableSettings.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;AACF","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 16px 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableSettings-module__box--Inl4q`
};
export default ___CSS_LOADER_EXPORT___;

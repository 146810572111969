import React from "react";
import { TableProps } from "antd";
import { ColumnType } from "antd/es/table";
import {
  ZRelatedTask,
  ZTaskDeletion,
} from "src/businessServices/services/bsScheduling/SchedulingTypes";
import { t } from "i18next";
import { classNames } from "src/common/classNames";
import styles from "./DeletionInfoTable.module.less";

export type DeletionTRow = ZTaskDeletion & {
  recalculatedTasks?: ZRelatedTask[];
  rowSpan?: number;
};

const buildDefaultProps = (
  key: keyof DeletionTRow,
  title: string,
): ColumnType<DeletionTRow> => ({
  key,
  dataIndex: key,
  title,
  width: "33%",
  className: classNames([styles.verticalAlign, styles.borderRight]),
});

export const buildDelTableColumns = (): TableProps["columns"] => [
  {
    ...buildDefaultProps("name", t("Deletion task")),
    render: (_, { groupName, name }: DeletionTRow) => (
      <div>
        <div className={styles.secondary}>{groupName}:</div>
        {name}
      </div>
    ),
  },
  {
    ...buildDefaultProps("relatedTasks", t("Related tasks")),
    render: (_, { relatedTasks }: DeletionTRow) => (
      <DelTaskList taskList={relatedTasks} />
    ),
  },
  {
    ...buildDefaultProps(
      "recalculatedTasks",
      t("Recalculation of planned dates for tasks"),
    ),
    className: styles.verticalAlign,
    render: (_, { recalculatedTasks, rowSpan }: DeletionTRow) => ({
      children: <DelTaskList taskList={recalculatedTasks} />,
      props: {
        rowSpan: rowSpan || 0,
      },
    }),
  },
];

interface PropsDelTaskList {
  taskList?: ZRelatedTask[];
}

const DelTaskList: React.FC<PropsDelTaskList> = ({ taskList }) =>
  taskList?.map(({ name, tasks }) => (
    <>
      <div className={styles.secondary}>{name}</div>
      <ul className={styles.taskList}>
        {tasks.map((taskName) => (
          <li>{taskName}</li>
        ))}
      </ul>
    </>
  ));

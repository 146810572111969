import { Spin } from "antd";
import * as React from "react";
import { gateway } from "src/common/apiUrl";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import { version } from "src/version";
import { z } from "zod";
import styles from "./VersionTab.module.less";

const zVersionDef = z.object({
  serviceName: z.string(),
  version: z.string(),
});

type ZVersionDef = z.infer<typeof zVersionDef>;

const loadVersions = () =>
  rest
    .get(gateway("/versions"))
    .then((res) => [
      { serviceName: "Front-end version", version },
      ...zVersionDef.array().parse(res.data),
    ]);

export const VersionTab: React.FC = () => {
  const [wait, setWait] = React.useState(false);
  const [data, setData] = React.useState<ZVersionDef[]>([]);
  React.useEffect(() => {
    setWait(true);
    loadVersions()
      .then(setData)
      .finally(() => setWait(false))
      .catch(onError);
  }, []);
  if (wait) return <Spin size="large" />;
  return (
    <div className={styles.box}>
      {data.map((el) => (
        <div>
          {el.serviceName}: <b>{el.version}</b>
        </div>
      ))}
    </div>
  );
};

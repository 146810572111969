import { TableResponse } from "src/components/tables/TableStore";
import { apiAuditUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import {
  AuditEntityValueFilters,
  ZAuditEntityValue,
  zAuditEntityValueResponse,
} from "./EntityAttsHistory.types";
import { ParamsLoadHistory, loadEntityHistory } from "../apiEntityHistory";
import {
  zAuditUserFilter,
  ZAuditUserFilter,
} from "../EntityStatesHistory/EntityStatesHistory.types";

export const loadEntityAttsHistory = async (
  params: ParamsLoadHistory<AuditEntityValueFilters>,
): Promise<TableResponse<ZAuditEntityValue>> =>
  loadEntityHistory("/entity-values", params, zAuditEntityValueResponse);

export const loadValuesUserFilter = async (
  id: number,
): Promise<ZAuditUserFilter[]> => {
  const resp = await rest.get(apiAuditUrl(`/entity-values-users/${id}`));
  return zAuditUserFilter.array().parse(resp.data);
};

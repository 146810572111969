// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__container--R7zgF li {
  background: white;
  list-style-type: none;
}
.src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__container--R7zgF .src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__checkBoxWrapper--Ue_l7 {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__container--R7zgF .src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__columnInfo--DW1Ov {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  gap: 15px;
  cursor: move;
  padding: 8px 24px;
}
.src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__container--R7zgF .src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__columnInfo--DW1Ov:hover {
  background: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/ColumnsSettings/ColumnSettingsList/ColumnSettingsList.module.less"],"names":[],"mappings":"AAAA;EAEI,iBAAA;EACA,qBAAA;AAAJ;AAHA;EAOI,aAAA;EACA,mBAAA;EACA,SAAA;AADJ;AARA;EAaI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,iBAAA;AAFJ;AAGI;EACE,mBAAA;AADN","sourcesContent":[".container {\n  li {\n    background: white;\n    list-style-type: none;\n  }\n  \n  .checkBoxWrapper {\n    display: flex;\n    flex-direction: row;\n    gap: 15px\n  }\n  \n  .columnInfo {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    flex-direction: row;\n    gap: 15px;\n    cursor: move;\n    padding: 8px 24px;\n    &:hover {\n      background: #f0f0f0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__container--R7zgF`,
	"checkBoxWrapper": `src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__checkBoxWrapper--Ue_l7`,
	"columnInfo": `src-components-tables-ColumnsSettings-ColumnSettingsList-ColumnSettingsList-module__columnInfo--DW1Ov`
};
export default ___CSS_LOADER_EXPORT___;

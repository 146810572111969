/* eslint class-methods-use-this: "off" */
/* eslint no-plusplus: "off" */

export class CellPosGenerator {
  protected x = 0;

  protected y = 0;

  next(isRowBegin: boolean): { x: number; y: number } {
    if (isRowBegin && !(this.x === 0 && this.y === 0)) {
      this.x = 0;
      this.y += 1;
    }
    return {
      x: this.x++,
      y: this.y,
    };
  }
}

import { ZMCPoint, ZMCRow } from "../ZMeasurementChart";
import { makeMcPointKey } from "./mChartCellKeys";
import { MChartEntityStore } from "./MChartEntityStore";

const isValueEmpty = (v: unknown) => v === undefined || v === null;

type SavePointParams<
  Field extends keyof ZMCPoint,
  TValue extends ZMCPoint[Field],
> = {
  mcStore: MChartEntityStore;
  row: ZMCRow;
  value: TValue;
  field: Field;
};

export const savePointDependentValues = async <
  Field extends keyof ZMCPoint,
  TValue extends ZMCPoint[Field],
>(
  params: SavePointParams<Field, TValue>,
  saversOther: (() => Promise<void>)[],
): Promise<ZMCPoint> => {
  const { mcStore, row, field, value } = params;
  const [res] = await Promise.all([
    mcStore.savePointValue(row, field, value),
    ...saversOther.map((save) => save()),
  ]);
  return res;
};

const makePointDependentDataSaver = async <
  Field extends keyof ZMCPoint,
  TValue extends ZMCPoint[Field],
>(
  params: SavePointParams<Field, TValue>,
  condition: () => boolean,
) => {
  const { mcStore, row, value, field } = params;
  if (condition()) {
    const depCellKey = makeMcPointKey(row, field);
    await mcStore.sheetStore.cellTask(depCellKey, async () => {
      await mcStore.savePointValue(row, field, value);
    });
    // Использовать sheetStore.setValueByKey не получилось, т.к начинается бесконечная рекурсия
  }
};

export const makePointDependentDataSaverTol =
  <Field extends keyof ZMCPoint, TValue extends ZMCPoint[Field]>(
    params: SavePointParams<Field, TValue>,
  ) =>
  async () =>
    makePointDependentDataSaver(params, () => {
      const { row, value, field } = params;
      return !row.mcPoint[field] && !!value;
    });

export const makePointDependentDataSaverScale =
  <Field extends keyof ZMCPoint, TValue extends ZMCPoint[Field]>(
    params: SavePointParams<Field, TValue>,
  ) =>
  async () =>
    makePointDependentDataSaver(params, () => {
      const { row, value, field } = params;
      return isValueEmpty(row.mcPoint[field]) && !isValueEmpty(value);
    });

const updatePointDependentData = <
  Field extends keyof ZMCPoint,
  Field2 extends keyof ZMCPoint,
>(
  mcStore: MChartEntityStore,
  row: ZMCRow,
  value: ZMCPoint,
  src: Field,
  dep: Field2,
  condition: () => boolean,
) => {
  mcStore.updatePointValue(row, value, src);
  if (condition()) {
    const newVal = { ...value, [dep]: value[src] };
    mcStore.updatePointValue(row, newVal, dep);
  }
};

export const updatePointDependentDataTol = <
  Field extends keyof ZMCPoint,
  Field2 extends keyof ZMCPoint,
>(
  mcStore: MChartEntityStore,
  row: ZMCRow,
  value: ZMCPoint,
  src: Field,
  dep: Field2,
) =>
  updatePointDependentData(
    mcStore,
    row,
    value,
    src,
    dep,
    () => !row.mcPoint[dep] && !!value[src],
  );

export const updatePointDependentDataScale = <
  Field extends keyof ZMCPoint,
  Field2 extends keyof ZMCPoint,
>(
  mcStore: MChartEntityStore,
  row: ZMCRow,
  value: ZMCPoint,
  src: Field,
  dep: Field2,
) =>
  updatePointDependentData(
    mcStore,
    row,
    value,
    src,
    dep,
    () => isValueEmpty(row.mcPoint[dep]) && !isValueEmpty(value[src]),
  );

import { z } from "zod";

export enum TableSettingsType {
  entities = "entities",
  valueObjectId = "valueObjectId",
  childObjectId = "childObjectId",
}

export const zSortDirection = z.enum(["ascend", "descend"]);
export type ZSortDirection = z.infer<typeof zSortDirection>;

export const zTableSettingsSort = z.object({
  attributeId: z.number(),
  direction: zSortDirection,
});

export const zDisplayAttr = z.object({
  id: z.number(),
  settings: z.string().nullable(),
});
export type ZDisplayAttr = z.infer<typeof zDisplayAttr>;

export const zObjTableSettings = z.object({
  type: z.nativeEnum(TableSettingsType),
  sort: zTableSettingsSort.nullable(),
  displayAttributes: zDisplayAttr.array(),
});
export type ZObjTableSettings = z.infer<typeof zObjTableSettings>;

export const zObjTableSettingsList = z.object({
  isUniformly: z.boolean(),
  tables: zObjTableSettings.array().nullable(),
});
export type ZObjTableSettingsList = z.infer<typeof zObjTableSettingsList>;

export const zColumnSettings = z.object({
  width: z.number().optional(),
});
export type ZColumnSettings = z.infer<typeof zColumnSettings>;

import { makeAutoObservable } from "mobx";
import { delay } from "src/common/delay";
import { Cell } from "./Cell";
import { isSymbolKey, KeyDef } from "./isSymbolKey";

export class SheetStore {
  constructor() {
    makeAutoObservable(this);
  }

  cells: Record<string, Cell> = {};

  setCell(cell: Cell) {
    this.cells[cell.cellKey] = cell;
  }

  cellByKey(cellKey: string | null): Cell | null {
    return cellKey ? this.cells[cellKey] ?? null : null;
  }

  editing = false;

  activeKey: string | null = null;

  get activeCell(): Cell | null {
    return this.cellByKey(this.activeKey);
  }

  isViewMode(cellKey: string): boolean {
    return (
      !this.editing || cellKey !== this.activeKey || this.saving.has(cellKey)
    );
  }

  activate(cellKey: string | null, isEdit: boolean) {
    const prevCell = this.cellByKey(this.activeKey);
    if (prevCell?.cellKey === cellKey) {
      if (isEdit && !this.editing) {
        this.editing = true;
        this.activeCell?.focus(true);
      }
      return;
    }
    this.activeKey = cellKey;
    this.editing = false;
    const cell = this.cellByKey(cellKey);
    if (cell) {
      this.editing = isEdit;
      cell.focus(isEdit);
    }
  }

  stepTo(x: number, y: number): boolean {
    const newCell = Object.values(this.cells).find(
      ({ pos }) => pos.x === x && pos.y === y,
    );
    if (newCell) {
      this.activate(newCell.cellKey, false);
      return true;
    }
    return false;
  }

  findRelative(
    x: number,
    y: number,
    to: "mostLeft" | "mostRight",
  ): Cell | null {
    const cells = Object.values(this.cells);
    let dstCell: Cell | null = null;
    if (to === "mostLeft") {
      cells.forEach((cell) => {
        const { pos } = cell;
        if (pos.y === y && (!dstCell || pos.x < dstCell.pos.x)) {
          dstCell = cell;
        }
      });
    } else if (to === "mostRight") {
      cells.forEach((cell) => {
        const { pos } = cell;
        if (pos.y === y && (!dstCell || pos.x > dstCell.pos.x)) {
          dstCell = cell;
        }
      });
    }
    return dstCell;
  }

  // -- keys
  onArrow(dx: number, dy: number) {
    if (this.editing) return;
    const { activeCell } = this;
    if (activeCell) {
      this.stepTo(activeCell.pos.x + dx, activeCell.pos.y + dy);
    }
  }

  onSkipToBound(mode: "mostLeft" | "mostRight") {
    if (this.editing) return;
    const { activeCell } = this;
    if (activeCell) {
      const dst = this.findRelative(activeCell.pos.x, activeCell.pos.y, mode);
      if (dst) {
        this.activate(dst.cellKey, false);
      }
    }
  }

  onEnter(dy: -1 | 1 = 1) {
    const { activeCell } = this;
    if (activeCell) {
      // Сейчас требования такие, что при нажатии на Enter всегда переход на ячейку ниже
      if (!this.stepTo(activeCell.pos.x, activeCell.pos.y + dy))
        this.blur(activeCell);
    }
  }

  onDelete() {
    const { activeCell, editing } = this;
    if (!editing && activeCell) {
      this.setCurCellValue(activeCell.cellKey, activeCell.emptyValue);
      this.blur(activeCell);
    }
  }

  onBackspace() {
    this.onDelete();
  }

  onKeyDown(cellKey: string, e: KeyDef) {
    // Чтобы не обрабатывать какие-то случаи (н.р. Enter в TextArea), надо их анализировать снаружи и не вызывать функцию
    const inView = this.isViewMode(cellKey);
    if (inView && isSymbolKey(e)) {
      this.activate(cellKey, true);
      // после активации ячейки нажатый символ передаётся в компонент.
      // Input после фокуса выделяет всё своё содержимое, поэтому новый символ замещает содержимое.
    } else if (e.key === "ArrowDown") {
      this.onArrow(0, 1);
    } else if (e.key === "ArrowUp") {
      this.onArrow(0, -1);
    } else if (e.key === "ArrowLeft") {
      this.onArrow(-1, 0);
    } else if (e.key === "ArrowRight") {
      this.onArrow(1, 0);
    } else if (e.key === "Enter") {
      this.onEnter(e.shiftKey ? -1 : 1);
    } else if (e.key === "Escape") {
      this.resetCurValue(cellKey, true);
    } else if (e.key === "Delete") {
      this.onDelete();
    } else if (e.key === "Backspace") {
      this.onBackspace();
    } else if (e.key === "F2") {
      this.activate(this.activeKey, true);
    } else if (e.key === "Home") {
      this.onSkipToBound("mostLeft");
    } else if (e.key === "End") {
      this.onSkipToBound("mostRight");
    }
  }

  cellSrcValues: Record<string, unknown> = {};

  cellCurValues: Record<string, unknown> = {};

  setSrcCellValue(cellKey: string, value: unknown) {
    this.cellSrcValues[cellKey] = value;
    this.setCurCellValue(cellKey, value);
  }

  setCurCellValue(cellKey: string, value: unknown) {
    this.cellCurValues[cellKey] = value;
  }

  resetCurValue(cellKey: string, stopEdit: boolean) {
    this.setCurCellValue(cellKey, this.cellSrcValues[cellKey]);
    if (stopEdit) {
      this.editing = false;
      this.clearError(cellKey);
    }
  }

  saving = new Set<string>();

  startSaving(cellKey: string) {
    this.saving.add(cellKey);
  }

  stopSaving(cellKey: string) {
    this.saving.delete(cellKey);
  }

  errors: Record<string, string> = {};

  setError(cellKey: string, errMsg: string) {
    this.errors[cellKey] = errMsg;
  }

  clearError(cellKey: string) {
    delete this.errors[cellKey];
  }

  blur(cell: Cell | null) {
    if (!cell) return;
    this.editing = false;
    const { cellKey: key, validate, save } = cell;
    const asyncBlur = async () => {
      try {
        this.clearError(key);
        this.startSaving(key);
        await delay(1); // Иногда компонент что-то меняет после потери фокуса. InputNumber
        const srcValue = this.cellSrcValues[key];
        const curValue = this.cellCurValues[key];
        if (curValue === srcValue) return;
        if (validate) {
          await validate(curValue);
        }
        await save(curValue);
      } catch (e) {
        this.setError(key, e.message);
      } finally {
        this.stopSaving(key);
      }
    };
    asyncBlur();
  }
}

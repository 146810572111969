import * as React from "react";
import { apiLoadPersonList } from "src/common/apiPerson";
import { loadEntityAttsForShow } from "src/common/attributes/composeAttrLabel2/labelLoaders/utils";
import {
  AttrLabelTreeData,
  defUsersListAttrLabelTreeData,
} from "src/common/attributes/composeAttrLabel2";
import { onError } from "src/common/onError";
import { Spin, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { AttrData } from "../AttributeHistrory.types";

export const AttrUsersList: React.FC<AttrData> = ({ value }) => {
  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState<
    ColumnType<Record<number, string | undefined>>[]
  >([]);
  const [users, setUsers] = React.useState<
    Record<number, string | undefined>[]
  >([]);

  const setColumnsFromTreeData = (data: AttrLabelTreeData[][]) => {
    const columnsList =
      data[0]?.map(({ attr }) => ({
        dataIndex: attr.id,
        title: attr.name,
      })) ?? [];
    columnsList.sort((a, b) => (a.title > b.title ? 1 : -1));
    setColumns(columnsList);
  };

  const setUsersFromTreeData = (data: AttrLabelTreeData[][]) => {
    const usersList = data
      .map((attrs) =>
        attrs.reduce(
          (acc, { attr, labels: [label] }) => {
            acc[attr.id] = label;
            return acc;
          },
          {} as Record<number, string | undefined>,
        ),
      )
      .map((cur, index) => ({ ...cur, id: index }));
    setUsers(usersList);
  };

  const init = async () => {
    setLoading(true);
    try {
      const data = await apiLoadPersonList({
        roleIds: [],
        userIds: value.split(","),
      });
      const attsResp = await Promise.all(
        data.map(async (user) => {
          const res = await loadEntityAttsForShow(
            user,
            user.attributeValues.map((attr) => attr.attributeId.toString()),
          );
          return res.map((r) =>
            defUsersListAttrLabelTreeData(
              r.labels,
              r.attr,
              user.userId,
              r.children,
              r.recursion,
            ),
          );
        }),
      );
      setColumnsFromTreeData(attsResp);
      setUsersFromTreeData(attsResp);
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return loading ? (
    <Spin spinning={loading}>...</Spin>
  ) : (
    <Table
      style={{ marginTop: 8 }}
      key="id"
      size="small"
      bordered
      dataSource={users}
      columns={columns}
      pagination={false}
    />
  );
};

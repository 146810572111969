// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__secondary--WfD1j {
  color: #0000008C;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__taskList--wkqVt {
  padding-left: 20px;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__verticalAlign--mnP5T {
  vertical-align: top;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__borderRight--Dn8Fx {
  border-right: 1px solid #F0F0F0;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__borderRight--Dn8Fx::before {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingControl/SchedulingTable/SchedTableToolbar/DeleteButton/DeletionInfoTable/DeletionInfoTable.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACA;EACE,kBAAA;AACF;AACA;EACE,mBAAA;AACF;AACA;EACE,+BAAA;AACF;AAAE;EACE,aAAA;AAEJ","sourcesContent":[".secondary {\n  color: #0000008C,\n}\n.taskList {\n  padding-left: 20px;\n}\n.verticalAlign {\n  vertical-align: top;\n}\n.borderRight {\n  border-right: 1px solid #F0F0F0;\n  &::before {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondary": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__secondary--WfD1j`,
	"taskList": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__taskList--wkqVt`,
	"verticalAlign": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__verticalAlign--mnP5T`,
	"borderRight": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedTableToolbar-DeleteButton-DeletionInfoTable-DeletionInfoTable-module__borderRight--Dn8Fx`
};
export default ___CSS_LOADER_EXPORT___;

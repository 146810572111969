import { makeAutoObservable } from "mobx";
import { SheetStore } from "../Sheet/SheetStore";
import { ZDemo1Row } from "./ZDemo1Data";
import { loadDemo1, saveCell1 } from "./apiDemo1";

export const mkColKey1 = (row: ZDemo1Row, field: keyof ZDemo1Row) =>
  `${row.id}:${field}`;

export const demo1Store = makeAutoObservable({
  sheetStore: new SheetStore(),
  data: [] as ZDemo1Row[],
  setData(newData: ZDemo1Row[]) {
    this.data = newData;
  },

  loading: false,
  setLoading(value: boolean) {
    this.loading = value;
  },

  error: null as Error | null,
  setError(err: Error | null) {
    this.error = err;
  },

  async init() {
    await this.reload();
    const row0 = this.data[0];
    if (row0) {
      this.sheetStore.activate(mkColKey1(row0, "name"), false);
    }
  },
  async reload() {
    this.setError(null);
    this.setLoading(true);
    try {
      const data = await loadDemo1();
      this.setData(data);
    } catch (error) {
      this.setError(error);
    } finally {
      this.setLoading(false);
    }
  },

  /**
   * После успешного сохранения нужно обновить данные стора.
   * В данном случае меняется одна ячейка. Но в принципе може и вся таблица
   */
  fieldSaver<F extends keyof ZDemo1Row>(srcRow: ZDemo1Row, field: F) {
    return cellSaver(
      // TODO: нужен предохранитель от устаревших запросов
      async (value) => {
        await saveCell1(srcRow.id, field, value);
        return value;
      },
      (value) => this.updateData(srcRow, field, value as ZDemo1Row[F]),
    );
  },

  updateData<F extends keyof ZDemo1Row>(
    srcRow: ZDemo1Row,
    field: F,
    value: ZDemo1Row[F],
  ) {
    const dstRow = this.data.find(({ id }) => id === srcRow.id);
    if (dstRow) {
      dstRow[field] = value;
    }
  },

  statuses: {
    1: "Не активен",
    2: "Активен",
    3: "Заморожен",
    4: "Заблокирован",
  } as Record<number, string>,
  get statusOptions(): { label: string; value: number }[] {
    return Object.keys(this.statuses)
      .map((sn) => +sn)
      .map((value: number) => ({ value, label: this.statuses[value] ?? "" }));
  },
});

const cellSaver =
  <TRes = void>(
    save: (value: unknown) => Promise<TRes>,
    onFinish: (value: TRes) => void,
  ) =>
  async (value: unknown) => {
    onFinish(await save(value));
  };

import { observer } from "mobx-react-lite";
import React from "react";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { ObjTableControlStore } from "../ObjTableControl/ObjTableControlStore";
import { zSortDirection, ZSortDirection } from "../ObjTableSettingsTypes";

interface PropsSortOrderSelect {
  store: ObjTableControlStore;
}

export const SortOrderSelect: React.FC<PropsSortOrderSelect> = observer(
  ({ store }) => {
    const { tableStore, isDisabled } = store;
    if (!tableStore) return null;
    const { sort, sortOrder, params } = tableStore;
    const handleChange = (value: ZSortDirection) => {
      const isAllowed = sort && value;
      tableStore.setParams({
        ...params,
        sort: isAllowed ? sort : undefined,
        sortOrder: isAllowed ? value : undefined,
      });
      tableStore.reload();
      store.saveSettings();
    };
    const options: DefaultOptionType[] = [
      { value: zSortDirection.Enum.ascend, label: "По возрастанию" },
      { value: zSortDirection.Enum.descend, label: "По убыванию" },
    ];
    return (
      <Select
        disabled={isDisabled || !sort}
        options={options}
        value={sortOrder}
        showSearch
        optionFilterProp="filterProp"
        allowClear
        onChange={handleChange}
        placeholder="Выбрать"
      />
    );
  },
);

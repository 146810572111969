import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { langHdr } from "src/lang/langHdr";
import {
  EntityFilterItem,
  ZEntityFilterValue,
  zEntityFilterValue,
} from "./types";

export const loadTreeAttrValues = async (
  objectId: number,
  filters: EntityFilterItem[],
): Promise<ZEntityFilterValue[]> => {
  const resp = await rest.post<ZEntityFilterValue[]>(
    apiObjUrl("/v2/values/search"),
    {
      objectId,
      filters,
    },
    {
      headers: langHdr(),
    },
  );
  return zEntityFilterValue.array().parse(resp.data);
};

import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import {
  CtrlColumns,
  TableLoadParams,
  TableStore,
} from "src/components/tables/TableStore";
import { compoundEntityTableStore2 } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore2";
import {
  ZEntityRow,
  EntityFilters,
} from "src/pages/EntityFiltersPage/EntityList/types";
import { ZAttribute } from "src/types/ZAttribute";
import {
  ZDisplayAttr,
  ZObjTableSettings,
  TableSettingsType,
} from "../ObjTableSettingsTypes";
import { getColumnSettings } from "./getColumnSettings";

export class ObjTableControlStore {
  constructor(
    private loading: () => boolean,
    private setLoading: (flag: boolean) => void,
    private save: () => Promise<void>,
    private disabled: () => boolean,
    readonly sortableAttrs: ZAttribute[],
  ) {
    makeAutoObservable(this);
  }

  tableStore: TableStore<ZEntityRow, EntityFilters> | null = null;

  setTableStore(store: TableStore<ZEntityRow, EntityFilters>) {
    this.tableStore = store;
  }

  type: TableSettingsType | null = null;

  setType(newType: TableSettingsType) {
    this.type = newType;
  }

  displayAttrs: ZDisplayAttr[] | null = null;

  setDisplayAttrs(attrs: ZDisplayAttr[] | null) {
    this.displayAttrs = attrs;
  }

  async init(objectId: number, initialSettings: ZObjTableSettings) {
    const { sort, type, displayAttributes } = initialSettings;
    this.setType(type);
    const initialParams: TableLoadParams<EntityFilters> = {
      page: 0,
      pageSize: 3,
      sort: sort ? String(sort.attributeId) : undefined,
      sortOrder: sort ? sort.direction : undefined,
      filters: { objectId },
    };
    try {
      this.setLoading(true);
      this.setDisplayAttrs(displayAttributes);
      this.setTableStore(
        await compoundEntityTableStore2(
          objectId,
          "id",
          initialParams,
          undefined,
          () => {
            if (this.displayAttrs?.length) {
              this.setTableSettings(this.displayAttrs, initialParams);
              this.setDisplayAttrs(null);
            }
          },
          undefined,
          { unsortable: true, unsavable: true },
        ),
      );
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setTableSettings(
    columns: ZDisplayAttr[],
    params?: TableLoadParams<EntityFilters>,
  ) {
    const { tableStore } = this;
    // если массив с колонками пуст - это значит, что настройка для них не производилась
    if (!tableStore || !columns) return;
    const settingsColumns = new Map(
      columns.map(({ id, settings }) => [String(id), settings]),
    );
    // порядок;
    tableStore.setColumnOrder(columns.map(({ id }) => String(id)));
    tableStore.columns.forEach(({ key }) => {
      // ширина;
      const colSettings = settingsColumns.get(key);
      tableStore.setColumnWidth(
        key,
        colSettings ? getColumnSettings(colSettings)?.width ?? 150 : 150,
      );
      // видимость;
      tableStore.setColumnVisible(key, !!colSettings);
    });
    // параметры
    if (params) {
      tableStore.setParams(params);
      tableStore.reload();
    }
  }

  get columnStore(): CtrlColumns | null {
    const { tableStore } = this;
    if (!tableStore) return null;
    return {
      ...(tableStore as CtrlColumns),
      isColumnVisible: (key) => tableStore.isColumnVisible(key),
      canColumnHide: (key) => tableStore.canColumnHide(key),
      setColumnVisible: async (key, visible) => {
        tableStore.setColumnVisible(key, visible);
        await this.save();
      },
      setColumnOrder: async (newOrder) => {
        tableStore.setColumnOrder(newOrder);
        await this.save();
      },
    };
  }

  saveSettings() {
    this.save();
  }

  get isLoading(): boolean {
    return this.loading();
  }

  get isDisabled(): boolean {
    return this.isLoading || this.disabled();
  }

  get settings(): ZObjTableSettings | null {
    const { type, tableStore } = this;
    if (!tableStore || !type) return null;
    const { sort: tableSort, sortOrder, finalColumns } = tableStore;
    const sort =
      tableSort && sortOrder
        ? {
            attributeId: Number(tableSort),
            direction: sortOrder,
          }
        : null;
    const displayAttributes = finalColumns.map(({ key, width }) => {
      const id = Number(key);
      return {
        id,
        settings: JSON.stringify({ width: width ? Number(width) : undefined }),
      };
    });
    return {
      type,
      sort,
      displayAttributes,
    };
  }
}

import { registerPageStore } from "src/pages/RegisterPage/RegisterPageStore";
import { ifDef } from "./ifDef";

export const gateway = (path: string) => `/gateway-service${path}`;

export const gatewayServices = (path: string) => gateway(`/services${path}`);

// Для случая, когда выполняется регистрация пользователя в неавторизованном режиме,
// происходит проксирование запросов в другой сервис.
const withRegistration = (prefix: string, path: string) =>
  ifDef(registerPageStore?.settingsId, (id) =>
    apiExtRegUrl(`/registration/${id}${path}`),
  ) ?? `${prefix}${path}`;

export const apiFilesUrl = (path: string) => `/file-service/api/files${path}`;

export const apiAdminUrl = (path: string) =>
  gatewayServices(`/admin-service/api${path}`);

export const apiObjUrl = (path: string) =>
  withRegistration(gatewayServices("/ms-object/api"), path);

export const apiI18nUrl = (path: string) => `/i18n/api${path}`;

export const apiAuditUrl = (path: string) => apiObjUrl(`/audit${path}`);

// Новый
export const apiAuthUrl = (path: string) => ` /auth-service/api${path}`;
// Старый
// export const apiAuthUrl = (path: string) => `/api/auth${path}`;

// только для rest. не для ws
export const apiPushUrl = (path: string) => `/push-service/api${path}`;

export const apiExtRegUrl = (path: string) =>
  gatewayServices(`/external-registration-service/api${path}`);

export const apiChatUrl = (path: string) =>
  gatewayServices(`/chat-service/api${path}`);

export const apiBomUrl = (path: string) =>
  gatewayServices(`/bom-service${path}`);

export const apiMeasurementChart = (path: string) =>
  gatewayServices(`/measurement-chart-service/api${path}`);

export const apiTechpack = (path: string) =>
  gatewayServices(`/techpack-service/api${path}`);

export const apiPlanning = (path: string) =>
  gatewayServices(`/planning-service/api/planning${path}`);

export const apiAsrtTable = (path: string) =>
  gatewayServices(`/assortment-table-service/api${path}`);

export const apiConfigUrl = (path: string) => apiAdminUrl(`/config${path}`);

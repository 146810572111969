import { makeAutoObservable } from "mobx";
import React from "react";
import { TreeStdStore } from "src/components/TreeStd";
import { EntityFilterTreeData } from "./types";

export class AttsTreeStore {
  treeStdStore = new TreeStdStore<EntityFilterTreeData>();

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  expandedKeys: React.Key[] = [];

  setExpandedKeys(list: React.Key[]) {
    this.expandedKeys = [...list];
  }

  selectedKeys: React.Key[] = [];

  setSelectedKeys(list: React.Key[]) {
    this.selectedKeys = [...list];
  }

  get lastSelectedKey() {
    return this.selectedKeys[this.selectedKeys.length - 1];
  }

  clear() {
    this.treeStdStore.setTreeData([]);
    this.setExpandedKeys([]);
    this.setSelectedKeys([]);
  }

  constructor() {
    makeAutoObservable(this);
  }
}

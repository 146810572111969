// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-Sheet2Page-Sheet-CellInternalBox-CellInternalBox-module__box--mu0hR {
  display: flex;
  flex-direction: row;
  gap: 4px;
  position: relative;
}
.src-pages-Sheet2Page-Sheet-CellInternalBox-CellInternalBox-module__spin--l_kqI {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5rem;
}
.src-pages-Sheet2Page-Sheet-CellInternalBox-CellInternalBox-module__error--kGnQy {
  color: red;
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Sheet2Page/Sheet/CellInternalBox/CellInternalBox.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AACF;AACA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,qBAAA;AACF;AACA;EACE,UAAA;EACA,kBAAA;AACF","sourcesContent":[".box {\n  display: flex;\n  flex-direction: row;\n  gap: 4px;\n  position: relative;\n}\n.spin {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding-right: 0.5rem;\n}\n.error {\n  color: red;\n  align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-Sheet2Page-Sheet-CellInternalBox-CellInternalBox-module__box--mu0hR`,
	"spin": `src-pages-Sheet2Page-Sheet-CellInternalBox-CellInternalBox-module__spin--l_kqI`,
	"error": `src-pages-Sheet2Page-Sheet-CellInternalBox-CellInternalBox-module__error--kGnQy`
};
export default ___CSS_LOADER_EXPORT___;

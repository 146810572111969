import { FnLoad } from "src/components/tables/TableStore";
import { EntityFilters } from "src/pages/EntityFiltersPage/EntityList/types";
import { apiObjUrl } from "src/common/apiUrl";
import { loadTableData } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore2";
import { ZEntity } from "src/types/ZEntity";
import { ZIdName } from "src/types/ZIdName";
import { ifDef } from "src/common/ifDef";
import { PointOpt } from "./EditMCEntity/MChartTable/PointDict";
import { FilterMcPoints } from "./EditMCEntity/PointSelectModal";

const findAttr = (needAttrId: number, entity: ZEntity): string | undefined =>
  entity.attributeValues.find(({ attributeId }) => attributeId === needAttrId)
    ?.values?.[0];

const attrVal = (needAttrId: number, entity: ZEntity): string =>
  findAttr(needAttrId, entity) ?? "-";

const dictVal = (
  needAttrId: number,
  entity: ZEntity,
  valueList: ZIdName[],
): string => {
  const dictValue = findAttr(needAttrId, entity);
  if (!dictValue) return "-";
  return valueList.find(({ id }) => id === Number(dictValue))?.name ?? "-";
};

type ParamsLoadPointsTableData = {
  pointObjectId: number;
  pointPointNameAttrId: number;
  pointDescriptionAttrId: number;
  mcPointCategoryAttrId: number;
  categoryList: ZIdName[];
  exclusionEntityIds?: number[];
};

export const loadPointsTableData =
  ({
    pointObjectId,
    pointPointNameAttrId,
    pointDescriptionAttrId,
    mcPointCategoryAttrId,
    categoryList,
    exclusionEntityIds,
  }: ParamsLoadPointsTableData): FnLoad<PointOpt, FilterMcPoints> =>
  async ({ page, pageSize, filters }) => {
    const { content, totalElements } = await loadTableData<EntityFilters>(
      apiObjUrl(`/entities/search`),
      {
        page,
        pageSize,
        filters: {
          objectId: pointObjectId,
          query: filters?.query,
          dictionaryValueIds: ifDef(filters?.categoryId, (id) => [[id]]) ?? [],
          exclusionEntityIds,
        },
        // Поскольку сортировка должна быть неизменной без реакции на клик по шапке колонки, передаем ее напрямую параметрами
        sort: String(pointPointNameAttrId),
        sortOrder: "ascend",
      },
    );
    const dstList = content.map(
      (entity) =>
        ({
          value: entity.id,
          label: attrVal(pointPointNameAttrId, entity),
          desc: attrVal(pointDescriptionAttrId, entity),
          category: dictVal(mcPointCategoryAttrId, entity, categoryList),
        }) satisfies PointOpt,
    );
    return { rows: dstList, totalItems: totalElements };
  };

import { observer } from "mobx-react-lite";
import * as React from "react";
import { FilterFieldsDict } from "src/components/tables/FiltersForm";
import { Select } from "antd";
import { viewDateTimeUtc } from "src/common/formats";
import { EditFilled } from "@ant-design/icons";
import { AttrTypeName } from "src/types/AttrType";
import { t } from "i18next";
import { EntityAttsHistoryStore } from "./EntityAttsHistoryStore";
import { TimelineFacade } from "../TimelineFacade/TimelineFacade";
import {
  AuditEntityValueFilters,
  ZAuditEntityValue,
} from "./EntityAttsHistory.types";
import { RangePickerString } from "../RangePickerString";
import styles from "./EntityAttsHistory.module.less";
import { AttributeHistory } from "./AttributeHistory";

/* 
  Отображение логов по этим атрибутам не проработаны на бэке, поэтому скрыты
 */

interface PropsEntityAttsHistory {
  store: EntityAttsHistoryStore;
}

export const EntityAttsHistory: React.FC<PropsEntityAttsHistory> = observer(
  ({ store }) => {
    React.useEffect(() => {
      store.init();
    }, []);

    const getAttrTypeName = (valueType: number) =>
      store.attrTypesMap[valueType] as AttrTypeName;

    const renderRow = (row: ZAuditEntityValue): React.ReactNode => (
      <div className={styles.timelineChild}>
        {row.attributes.map(({ id, name, value, valueType }) => (
          <div key={`${row.timestamp}-${id}`}>
            <span className={styles.attTitle}>{name}:</span>&nbsp;
            {!value && (
              <span className={styles.attEmpty}>{t("Value cleared")}</span>
            )}
            {value && (
              <AttributeHistory
                type={getAttrTypeName(valueType)}
                value={value}
              />
            )}
          </div>
        ))}
        <div className={styles.changeInfo}>
          <span className={styles.timestamp}>
            {viewDateTimeUtc(row.timestamp)}
          </span>
          <span className={styles.fio}>
            <EditFilled />
            {row.fio || "N/A"}
          </span>
        </div>
      </div>
    );

    const filterItems: FilterFieldsDict<AuditEntityValueFilters> = {
      timestamp: {
        render: () => <RangePickerString />,
        itemProps: {
          label: t("Show for the period"),
          style: { minWidth: 215 },
        },
        defaultValue: null,
      },
      userId: {
        render: () => (
          <Select
            allowClear
            placeholder={t("Select")}
            options={store.userFilterOptions}
          />
        ),
        itemProps: { label: t("User") },
        defaultValue: null,
      },
      attributeId: {
        render: () => (
          <Select
            allowClear
            options={store.attributeFilterOptions}
            placeholder={t("Select")}
          />
        ),
        itemProps: { label: t("Attribute") },
        defaultValue: null,
      },
    };
    return (
      <TimelineFacade
        store={store.tableStore}
        filterItems={filterItems}
        renderRow={renderRow}
        title={t("Changing the value of attributes")}
      />
    );
  },
);

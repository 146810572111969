import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { ZAttrViewInfo } from "src/common/attrView";
import { ZAttribute } from "src/types/ZAttribute";
import { notification, TableProps } from "antd";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { t } from "i18next";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { onError } from "src/common/onError";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import {
  saveSchedStartDate,
  createTask,
  deleteTasks,
  loadSchedulingData,
  loadSchedulingSettings,
  checkDeleteTasks,
} from "../apiScheduling";
import { buildColumns } from "./SchedulingTable/buildColumns";
import {
  TaskDeletionError,
  ZColumnSettings,
  zColumnSettings,
  ZSchedulingRow,
  ZSchedulingSettings,
  ZTasksDeletionCheck,
} from "../SchedulingTypes";
import { searchTasks } from "./SchedulingTable/SchedTableToolbar/TaskSearch/searchTasks";
import { DeletionTRow } from "./SchedulingTable/SchedTableToolbar/DeleteButton/DeletionInfoTable/buildDelTableColumns";

export type AttrColumnDef = {
  attr: ZAttribute;
  viewInfo: ZAttrViewInfo | undefined;
};

export type TaskEditor = {
  cardStore: EntityCardStore;
  title: string;
};

export type TemplateCreator = {
  filterStore: EntityFiltersPageStore;
  fnCreate: (
    entityId: number,
    templateId: number,
    objectServiceId: number,
  ) => Promise<void>;
  title: string;
};

const buildColumnSettingsKey = (entityId: number) =>
  `schedulingSettings-${entityId}`;

export const schedulingControlStore = makeAutoObservable({
  data: { status: "none" } as RemoteData<ZSchedulingRow[]>,
  setData(data: RemoteData<ZSchedulingRow[]>) {
    this.data = data;
  },

  settings: null as ZSchedulingSettings | null,
  setSettings(settings: ZSchedulingSettings | null) {
    this.settings = settings;
  },

  entityId: null as number | null,
  setEntityId(id: number) {
    this.entityId = id;
  },

  objectServiceId: null as number | null,
  setObjectServiceId(id: number) {
    this.objectServiceId = id;
  },

  async init(entityId: number, objectServiceId: number) {
    try {
      this.setEntityId(entityId);
      this.setObjectServiceId(objectServiceId);
      this.hiddenColumns.clear();
      this.setSelectedRows([]);
      this.setSettings(null);
      this.setSearchQuery("");
      this.setColumnSettingsKey(buildColumnSettingsKey(entityId));
      this.loadColumnSettings();
      this.loadData();
    } catch (error) {
      onError(error);
    }
  },

  async loadData() {
    const { entityId, settings, objectServiceId } = this;
    try {
      if (!entityId) throw Error(t("Missing value", { parameter: "entityId" }));
      if (!objectServiceId)
        throw Error(t("Missing value", { parameter: "objectServiceId" }));
      this.setData({ status: "wait" });
      if (!settings) {
        const newSettings = await loadSchedulingSettings(objectServiceId);
        if (!newSettings.taskSettings)
          throw Error(t("The service has not been configured"));
        this.setSettings(newSettings);
      }
      const data = await loadSchedulingData(entityId, objectServiceId);
      this.setData({ status: "ready", result: [data] });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  },

  get projectInfo(): ZSchedulingRow | null {
    if (this.data.status !== "ready") return null;
    return this.data.result.find(({ type }) => type === "PLAN") ?? null;
  },

  operationLoading: false,
  setOperationLoading(flag: boolean) {
    this.operationLoading = flag;
  },

  async changeStartDate(date: string) {
    const { objectServiceId, entityId } = this;
    try {
      if (!objectServiceId)
        throw Error(t("Missing value", { parameter: "objectServiceId" }));
      if (!entityId) throw Error(t("Missing value", { parameter: "entityId" }));
      this.setOperationLoading(true);
      await saveSchedStartDate(entityId, date, objectServiceId);
      this.loadData();
    } catch (error) {
      onError(error);
    } finally {
      this.setOperationLoading(false);
    }
  },

  // table

  get columns(): TableProps["columns"] {
    const { settings } = this;
    return settings ? buildColumns(settings) : [];
  },

  get visibleColumns(): TableProps["columns"] {
    return this.columns?.filter(
      ({ key }) => !this.hiddenColumns.has(String(key)),
    );
  },

  hiddenColumns: new Set<string>(),
  toggleColumnVisibility(key: string) {
    const { hiddenColumns } = this;
    if (hiddenColumns.has(key)) {
      hiddenColumns.delete(key);
    } else {
      hiddenColumns.add(key);
    }
    this.saveColumnSettings();
  },

  get dataSource(): ZSchedulingRow[] {
    const { data, searchQuery } = this;
    if (data.status === "ready") {
      return searchQuery ? searchTasks(data.result, searchQuery) : data.result;
    }
    return [];
  },

  selectedRows: [] as ZSchedulingRow[],
  setSelectedRows(rowKeys: ZSchedulingRow[]) {
    this.selectedRows = rowKeys;
  },

  get selectedRowKeys(): number[] {
    return this.selectedRows.map(({ id }) => id);
  },

  get selectedTaskIds(): number[] {
    return this.selectedRows
      .filter(({ type }) => type === "TASK")
      .map(({ id }) => id);
  },

  searchQuery: "",
  setSearchQuery(query: string) {
    this.searchQuery = query;
  },

  columnWidths: {} as Record<string, number | string>,
  setColumnWidth(key: string, width: number | string) {
    this.columnWidths[key] = width;
    this.saveColumnSettings();
  },

  columnSettingsKey: null as string | null,
  setColumnSettingsKey(key: string | null) {
    this.columnSettingsKey = key;
  },

  loadColumnSettings() {
    const { columnSettingsKey } = this;
    try {
      if (!columnSettingsKey)
        throw Error(t("Missing value", { parameter: "columnSettingsKey" }));
      const settings = localStorage.getItem(columnSettingsKey);
      if (!settings) return;
      const json = JSON.parse(settings);
      const { hiddenColumns, columnWidths } = zColumnSettings.parse(json);
      hiddenColumns?.forEach((col) => this.toggleColumnVisibility(col));
      Object.entries(columnWidths ?? {}).forEach(([key, width]) =>
        this.setColumnWidth(key, width),
      );
    } catch (error) {
      onError(error);
    }
  },

  saveColumnSettings() {
    const { columnSettingsKey } = this;
    try {
      if (!columnSettingsKey)
        throw Error(t("Missing value", { parameter: "columnSettingsKey" }));
      const settings: ZColumnSettings = {
        hiddenColumns: Array.from(this.hiddenColumns),
        columnWidths: this.columnWidths,
      };
      localStorage.setItem(columnSettingsKey, JSON.stringify(settings));
    } catch (error) {
      onError(error);
    }
  },

  // task edit/create

  editor: null as TaskEditor | null,
  setEditor(ed: TaskEditor | null) {
    this.editor = ed;
  },

  openEditor(row: ZSchedulingRow) {
    const { id, type } = row;
    if (type !== "TASK") return;
    const cardStore = new EntityCardStore();
    cardStore.init(String(id));
    this.setEditor({ cardStore, title: row.name ?? "" });
  },

  closeEditor() {
    this.setEditor(null);
  },

  openCreation(taskObjId: number) {
    const { entityId, objectServiceId } = this;
    try {
      if (!entityId) throw Error(t("Missing value", { parameter: "entityId" }));
      if (!objectServiceId)
        throw Error(t("Missing value", { parameter: "objectServiceId" }));
      const cardStore = new EntityCardStore((ent) =>
        createTask(entityId, ent, objectServiceId),
      );
      this.setEditor({ cardStore, title: t("New task") });
      cardStore.initNew(taskObjId);
    } catch (error) {
      onError(error);
    }
  },

  onUpdate() {
    this.closeEditor();
    this.loadData();
  },

  // template-creation

  templateCreator: null as TemplateCreator | null,
  setTemplateCreator(ed: TemplateCreator | null) {
    this.templateCreator = ed;
  },

  closeTemplateCreator() {
    this.setTemplateCreator(null);
  },

  openTemplateCreation(
    templateId: number,
    title: string,
    fnCreate: (
      entityId: number,
      templateId: number,
      objectServiceId: number,
    ) => Promise<void>,
  ) {
    this.setTemplateCreator({
      filterStore: new EntityFiltersPageStore({
        actions: new Set([]),
        objectId: templateId,
        selectionSettings: { selectionType: "radio" },
        onRowClick: (row) => {
          const tableStore = this.templateCreator?.filterStore.tableStore;
          if (!tableStore) return;
          selectOnRowClick(row, tableStore);
        },
      }),
      title,
      fnCreate,
    });
  },

  async onTemplateCreate() {
    const { templateCreator, entityId, objectServiceId } = this;
    const templateEntityId =
      templateCreator?.filterStore.tableStore?.selected[0]?.id;
    try {
      if (!entityId) throw Error(t("Missing value", { parameter: "entityId" }));
      if (!objectServiceId)
        throw Error(t("Missing value", { parameter: "objectServiceId" }));
      if (!templateEntityId)
        throw Error(t("Missing value", { parameter: "templateEntityId" }));
      this.setOperationLoading(true);
      await templateCreator.fnCreate(
        entityId,
        templateEntityId,
        objectServiceId,
      );
      this.loadData();
    } catch (error) {
      onError(error);
    } finally {
      this.setOperationLoading(false);
      this.closeTemplateCreator();
    }
  },

  // delete

  taskDeletion: null as ZTasksDeletionCheck | null,
  setTaskDeletion(deletion: ZTasksDeletionCheck | null) {
    this.taskDeletion = deletion;
  },

  getDelMessage(name?: string, type?: TaskDeletionError | null): string {
    const deletionMessages: Record<TaskDeletionError, string> = {
      [TaskDeletionError.notFound]: t("Task was not found", { name }),
      [TaskDeletionError.inProgress]: t("Task is in progress", { name }),
      [TaskDeletionError.finished]: t("Task is completed", { name }),
    };
    return type ? deletionMessages[type] : "";
  },

  async checkDelete() {
    const { selectedTaskIds, entityId, objectServiceId } = this;
    try {
      if (!entityId) throw Error(t("Missing value", { parameter: "entityId" }));
      if (!objectServiceId)
        throw Error(t("Missing value", { parameter: "objectServiceId" }));
      this.setOperationLoading(true);
      const checkData = await checkDeleteTasks(
        entityId,
        selectedTaskIds,
        objectServiceId,
      );
      if (checkData.canDelete) {
        this.setTaskDeletion(checkData);
      } else {
        const isMultiDeletion = checkData.tasks.length > 1;
        const firstDeletedTask = checkData.tasks[0];
        notification.error({
          message: t(
            isMultiDeletion
              ? "The selected tasks contain tasks in the “In Progress” and/or “Completed” status!"
              : this.getDelMessage(
                  firstDeletedTask?.name ?? "",
                  firstDeletedTask?.error,
                ),
          ),
          description: t(
            isMultiDeletion
              ? "You cannot delete tasks. Select tasks in the “Created” state."
              : t("You cannot delete a task."),
          ),
        });
      }
    } catch (error) {
      onError(error);
    } finally {
      this.setOperationLoading(false);
    }
  },

  async doDelete() {
    const { selectedTaskIds, entityId, objectServiceId, taskDeletion } = this;
    try {
      if (!entityId) throw Error(t("Missing value", { parameter: "entityId" }));
      if (!objectServiceId)
        throw Error(t("Missing value", { parameter: "objectServiceId" }));
      this.setOperationLoading(true);
      this.setData({
        status: "ready",
        result: [await deleteTasks(entityId, selectedTaskIds, objectServiceId)],
      });
      this.setTaskDeletion(null);
      const isMultiDeletion = selectedTaskIds.length > 1;
      const firstDeletedTask = taskDeletion?.tasks.find(
        ({ id }) => id === selectedTaskIds[0],
      )?.name;
      notification.success({
        message: isMultiDeletion
          ? t("Tasks have been deleted.")
          : t("Task has been deleted.", { name: firstDeletedTask }),
        description: t(
          "Task bindings associated with the task(s) have been deleted. Scheduled start and end dates of tasks recalculated.",
        ),
      });
    } catch (error) {
      onError(error);
    } finally {
      this.setOperationLoading(false);
    }
  },

  get delTableData(): DeletionTRow[] {
    const { taskDeletion } = this;
    if (!taskDeletion) return [];
    return taskDeletion.tasks.map((task, index) => ({
      ...task,
      ...(index === 0 && {
        recalculatedTasks: taskDeletion.recalculatedTasks,
        rowSpan: taskDeletion.tasks.length,
      }),
    }));
  },
});

export type SchedulingControlStore = typeof schedulingControlStore;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-Sheet2Page-Demo1-Demo1-module__demo1--uiLDY {
  border: thin solid silver;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.src-pages-Sheet2Page-Demo1-Demo1-module__table--q5zoj {
  display: grid;
}
.src-pages-Sheet2Page-Demo1-Demo1-module__cell--cOWST {
  border: thin solid silver;
  padding: 2px;
}
.src-pages-Sheet2Page-Demo1-Demo1-module__infoBlock--WwIFo {
  margin-top: 1rem;
  color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Sheet2Page/Demo1/Demo1.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,yBAAA;EACA,YAAA;AADF;AAIA;EACE,gBAAA;EACA,WAAA;AAFF","sourcesContent":[".demo1 {\n  border: thin solid silver;\n  padding: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.table {\n  display: grid;\n}\n\n.cell {\n  border: thin solid silver;\n  padding: 2px;\n}\n\n.infoBlock {\n  margin-top: 1rem;\n  color: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"demo1": `src-pages-Sheet2Page-Demo1-Demo1-module__demo1--uiLDY`,
	"table": `src-pages-Sheet2Page-Demo1-Demo1-module__table--q5zoj`,
	"cell": `src-pages-Sheet2Page-Demo1-Demo1-module__cell--cOWST`,
	"infoBlock": `src-pages-Sheet2Page-Demo1-Demo1-module__infoBlock--WwIFo`
};
export default ___CSS_LOADER_EXPORT___;

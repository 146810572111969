import { makeAutoObservable } from "mobx";
import { loadObject } from "src/common/api/apiObject";
import { onError } from "src/common/onError";
import {
  SelectionSetings,
  TableLoadParams,
  TableStore,
} from "src/components/tables/TableStore";
import { walk } from "src/components/TreeStd";
import { hasPermissionIn, Permission } from "src/types/Permission";
import { TreeLikeData } from "src/types/TreeLikeData";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZObjState } from "src/types/ZObjState";
import { loadAttrTypeMap } from "src/common/attributes";
import { loadObjectStates } from "../ManagementPage/Obj2Tab/roles/rolesApi";
import {
  loadFilterSupportedAtts,
  loadObjectAttrinbutesAll,
  makeObjectAttributesMap,
} from "../ManagementPage/objectsApi";
import { AttsTreeStore } from "./AttsTree/AttsTreeStore";
import { loadTreeAttrValues } from "./AttsTree/loadTreeAttrValues";
import { attr2TreeData } from "./AttsTree/transforms";
import { EntityFilterTreeData, EntityFilterItem } from "./AttsTree/types";
import { CheckListWithOrderStore } from "./CheckListWithOrder/CheckListWithOrderStore";
import { DefaultTSettingsStore } from "./DefaultTSettingsStore";
import { EntFilterActionType } from "./EntityFiltersPage.types";
import { compoundEntityTableStore2 } from "./EntityList/compoundEntityTableStore2";
import { EntityFilters, ZEntityRow } from "./EntityList/types";

type EntitySearchState = {
  attsOrder: string[];
  attsSelected: string[];
  treeSlected: {
    treeData: TreeLikeData<EntityFilterTreeData>[];
    selected: string[];
    expanded: string[];
  };
};

const store2EntitySearchState = (
  store: EntityFiltersPageStore,
): EntitySearchState | null => ({
  attsOrder: store.cLOrderedStore.order.map(String),
  attsSelected: store.cLOrderedStore.checkListStore.selectedKeys.map(String),
  treeSlected: {
    treeData: store.attrsTreeStore.treeStdStore.treeData,
    selected: store.attrsTreeStore.selectedKeys.map(String),
    expanded: store.attrsTreeStore.expandedKeys.map(String),
  },
});

const createStorageKeyState = (objId: number) => `enitiesSearchState-${objId}`;
const createStorageKeyLP = (objId: number) => `enitiesSearchLP-${objId}`;

export const saveLoadParams = (
  params: TableLoadParams<EntityFilters> | undefined,
  objId: number,
) => {
  try {
    localStorage.setItem(createStorageKeyLP(objId), JSON.stringify(params));
  } catch (error) {
    onError(error);
  }
};

const getSavedLoadParams = (
  objId: number,
): TableLoadParams<EntityFilters> | null => {
  try {
    const data = localStorage.getItem(createStorageKeyLP(objId));
    return data ? JSON.parse(data) : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

export class EntityFiltersPageStore {
  constructor(props?: {
    objectId?: number;
    selectionSettings?: SelectionSetings<ZEntityRow>;
    onLoad?: () => void;
    onRowClick?: (row: ZEntityRow, index?: number) => void;
    actions?: Set<EntFilterActionType>;
    defTSettingsStore?: DefaultTSettingsStore;
  }) {
    const {
      objectId,
      onLoad,
      onRowClick,
      selectionSettings,
      actions,
      defTSettingsStore,
    } = props || {};
    if (selectionSettings) this.selectionSettings = selectionSettings;
    if (objectId) this.setCurrObjId(objectId);
    if (onLoad) this.onLoad = onLoad;
    if (onRowClick) this.onRowClick = onRowClick;
    this.availableActions =
      actions ??
      new Set([
        EntFilterActionType.changeState,
        EntFilterActionType.copy,
        EntFilterActionType.create,
        EntFilterActionType.export,
        EntFilterActionType.delete,
      ]);
    if (defTSettingsStore) this.defTSettingsStore = defTSettingsStore;
    makeAutoObservable(this);
  }

  protected availableActions: Set<EntFilterActionType>;

  private selectionSettings: SelectionSetings<ZEntityRow> = {
    keepSelected: true,
    selectionType: "checkbox",
  };

  private onLoad: (() => void) | null = null;

  defTSettingsStore: DefaultTSettingsStore | null = null;

  onRowClick: ((row: ZEntityRow, index?: number) => void) | null = null;

  cLOrderedStore = new CheckListWithOrderStore();

  attrsTreeStore = new AttsTreeStore();

  attributesMap: Record<number, ZAttribute> = {};

  setAttributesMap(map: Record<number, ZAttribute>) {
    this.attributesMap = map;
  }

  get avalibleActionsSet() {
    return this.availableActions;
  }

  currObjId: number | null = null;

  setCurrObjId(id: number) {
    this.currObjId = id;
  }

  objectStates: ZObjState[] = [];

  setObjectStates(list: ZObjState[]) {
    this.objectStates = list;
  }

  currObjName: string = "";

  setCurrObjName(name: string) {
    this.currObjName = name;
  }

  curObject: ZObjectItem | null = null;

  setCurObject(obj: ZObjectItem | null) {
    this.curObject = obj;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  siderVisible: boolean = true;

  setSiderVisible(flag: boolean) {
    this.siderVisible = flag;
  }

  typesMap: Record<number, string> = {};

  setTypesMap(map: Record<number, string>) {
    this.typesMap = map;
  }

  tableStore: TableStore<ZEntityRow, EntityFilters> | null = null;

  get canCreate(): boolean {
    const { curObject } = this;
    return (
      this.availableActions.has(EntFilterActionType.create) &&
      !!curObject &&
      hasPermissionIn(curObject, Permission.entityCreate)
    );
  }

  get canDelete(): boolean {
    const { curObject } = this;
    return (
      this.availableActions.has(EntFilterActionType.delete) &&
      !!curObject &&
      hasPermissionIn(curObject, Permission.entityDelete)
    );
  }

  get objectStatesOptions() {
    return this.objectStates.map((state) => ({
      label: state.name,
      value: state.id,
    }));
  }

  async init(
    objectId: number,
    options?: {
      statesLoader?: () => Promise<ZObjState[]>;
    },
  ) {
    try {
      this.setLoading(true);
      this.setCurObject(null);
      this.cLOrderedStore.clear();
      this.attrsTreeStore.clear();
      this.setCurrObjId(objectId);
      const object = await loadObject(objectId, { translate: true });
      this.setCurrObjName(object.name);
      this.setCurObject(object);
      this.setObjectStates(
        await loadObjectStates(objectId, { translate: true }),
      );
      this.setAttributesMap(
        makeObjectAttributesMap(
          await loadObjectAttrinbutesAll(String(objectId), {
            translate: true,
          }),
        ),
      );
      this.setTypesMap(await loadAttrTypeMap());
      const supportedAtts = await loadFilterSupportedAtts(String(objectId));
      const attsOptions = supportedAtts.map(({ id }) => ({
        label: this.attributesMap[id]?.name || "",
        value: String(id),
      }));

      this.cLOrderedStore.checkListStore.setOptions(attsOptions);
      await this.loadEntitySearchState();

      const savedParams = getSavedLoadParams(objectId);
      const savedFilters = savedParams?.filters || {};
      const defFilters: EntityFilters = { objectId };
      const finalFilters = {
        ...savedFilters,
        ...defFilters,
      };

      await this.defTSettingsStore?.init(objectId);
      const initDefSettings = () =>
        this.defTSettingsStore?.initSettings(this.tableStore, !savedParams);

      const finalParams = {
        ...(savedParams ?? this.defTSettingsStore?.sortParams),
        filters: finalFilters,
      };

      const { statesLoader } = options || {};
      this.tableStore = await compoundEntityTableStore2(
        objectId,
        "id",
        finalParams,
        this.selectionSettings,
        () => {
          saveLoadParams(this.tableStore?.params, objectId);
          this.onLoad?.();
          initDefSettings?.();
        },
        statesLoader,
      );
      this.reloadWithActualFilters();
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async appendFilterTree(node?: TreeLikeData<EntityFilterTreeData>) {
    try {
      if (!this.currObjId) throw new Error("System error: currObjId = null");
      const {
        attrsTreeStore: { treeStdStore },
        cLOrderedStore: { orderedSelected },
        typesMap,
      } = this;

      const attrIndex = orderedSelected.findIndex(
        ({ value }) => value === String(node?.attrId),
      );
      const exist = attrIndex !== -1;
      const actualIndex = exist ? attrIndex : 0;
      const nextIndex = exist ? actualIndex + 1 : 0;
      const nextKey = orderedSelected[nextIndex]?.value;
      const isLeaf = nextIndex === orderedSelected.length - 1;
      const attribute = this.attributesMap[Number(nextKey)];
      if (!attribute) return;
      this.attrsTreeStore.setLoading(true);
      const path = treeStdStore.findPath(node?.key || "") || [];
      const firstItem = { attrId: Number(nextKey) };
      const getParams = () => {
        if (node)
          return [
            firstItem,
            ...path
              .map((key) => treeStdStore.findNode(key))
              .map((item) => ({
                attrId: Number(item?.attrId),
                filterValue: item?.filterValue,
              }))
              .filter((item) => !!item),
          ];
        return [firstItem];
      };
      const attrFilterItems: EntityFilterItem[] = getParams();
      const loadedAttrs = await loadTreeAttrValues(
        this.currObjId,
        attrFilterItems,
      );
      const treeDataProm = loadedAttrs.map((value) =>
        attr2TreeData(attribute, value, isLeaf, typesMap),
      );
      if (node) {
        treeStdStore.appendNode(node.key, treeDataProm);
      } else {
        treeStdStore.setTreeData(treeDataProm);
      }
      this.saveEntitySearchState();
    } catch (error) {
      onError(error);
    } finally {
      this.attrsTreeStore.setLoading(false);
    }
  }

  resetFilterTree() {
    this.attrsTreeStore.clear();
    this.appendFilterTree();
    this.reloadWithActualFilters();
  }

  reloadWithActualFilters() {
    const {
      attrsTreeStore,
      attrsTreeStore: { treeStdStore },
    } = this;
    const nodes = (
      treeStdStore.findPath(attrsTreeStore.lastSelectedKey || "") || []
    )
      ?.map((key) => treeStdStore.findNode(key))
      .filter((e) => !!e) as TreeLikeData<EntityFilterItem>[];

    const lastNode = treeStdStore.findNode(
      attrsTreeStore.lastSelectedKey || "",
    );

    const hierarchicalFilter = {
      filters: nodes.map((n) =>
        lastNode?.key === n.key
          ? {
              attrId: n.attrId,
            }
          : {
              attrId: n.attrId,
              filterValue: n.filterValue,
            },
      ),
      searchValue: lastNode?.filterValue?.toSearch || "",
    };

    const actualFilters = {
      ...(this.tableStore?.filters || {}),
      hierarchicalFilter,
    };
    this.tableStore?.setFilters(actualFilters, true);
  }

  saveEntitySearchState() {
    try {
      if (!this.currObjId) throw new Error("System error: currObjId = null");
      const dataForSave = store2EntitySearchState(this);
      localStorage.setItem(
        createStorageKeyState(this.currObjId),
        JSON.stringify(dataForSave),
      );
    } catch (error) {
      onError(error);
    }
  }

  async loadEntitySearchState() {
    try {
      const data = this.getSavedSearchState();
      if (!this.currObjId || !data) return;
      const {
        cLOrderedStore,
        cLOrderedStore: { checkListStore },
        attrsTreeStore,
      } = this;
      checkListStore.setSelectedKeysSet(data.attsSelected);
      cLOrderedStore.setOrder(data.attsOrder);
      attrsTreeStore.setSelectedKeys(data.treeSlected.selected);
      attrsTreeStore.setExpandedKeys(data.treeSlected.expanded);
      /* чтобы дерево отобразилось кооректно
       * необходимо заново загрузить его структуру
       */
      await this.appendFilterTree();
      const treeLoadersList: (() => Promise<void>)[] = [];
      walk((node) => {
        const loadNodes = async () => {
          /**
           * нам не нужно грузить дерево полностью
           * загружаем только те ноды, которые были уже созданы
           */
          if (node.children && node.children.length > 0)
            await this.appendFilterTree(node);
        };
        treeLoadersList.push(loadNodes);
      }, data.treeSlected.treeData);
      await Promise.all(treeLoadersList.map((fn) => fn()));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  getSavedSearchState(): EntitySearchState | null {
    try {
      if (!this.currObjId) throw new Error("System error: currObjId = null");

      const data = localStorage.getItem(createStorageKeyState(this.currObjId));
      return data ? JSON.parse(data) : null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }
}

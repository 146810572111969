import * as React from "react";
import { Button, Form, Input, InputRef, Radio, Select, Space } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { trimRequired } from "src/common/validationRules/trimRequired";
import {
  ZViewType,
  internalMenuLinkUrls,
  viewType,
} from "src/components/MainFrame/LeftMenu/ZMenuItem";
import z from "zod";
import { t } from "i18next";
import { MultiLangFields } from "src/components/MultiLangFields";
import { filterOptionByName } from "src/common/filterOptionByName";
import { RoleSelect } from "src/components/RoleSelect";
import { safeFocus } from "src/common/safeFocus";
import * as icons from "@ant-design/icons/lib/icons";
import { Menu2TabStore } from "./Menu2TabStore";
import styles from "./Menu2Tab.module.less";
import { WidthLimitedFields } from "../WidthLimitedFields";
import { ExtraTools } from "../ExtraTools";
import { IconRenderer } from "./IconRenderer";

const viewTypeLabels: Record<ZViewType, string> = {
  bigGroup: "Группа сервисов",
  bigLink: "Сервис (1-ый уровень)",
  smallGroup: "Раздел сервиса (2-ой уровень)",
  smallLink: "Ссылка на раздел/сервис (пункт меню 2-ого уровня)",
};

export const linkType = ["text", "object", "internal", "external"] as const;
export const zLinkType = z.enum(linkType);
export type ZLinkType = z.infer<typeof zLinkType>;
const linkTypeLabels: Record<ZLinkType, string> = {
  text: "Без ссылки",
  object: "Ссылка на объект",
  internal: "Внутренняя ссылка",
  external: "Внешняя ссылка",
};

interface PropsMenuItemFields {
  store: Menu2TabStore;
}

export const MenuItemFields: React.FC<PropsMenuItemFields> = observer(
  ({ store }) => {
    const ref1 = React.useRef<InputRef>(null);
    const curViewType: ZViewType | null = Form.useWatch("viewType");
    const curLinkType: ZLinkType | null = Form.useWatch(["link", "type"]);

    React.useEffect(() => {
      safeFocus(ref1.current);
    }, [store.curSelect]);

    return (
      <WidthLimitedFields>
        <Form.Item
          name="name"
          label="Системное название"
          rules={[trimRequired()]}
        >
          <Input ref={ref1} allowClear />
        </Form.Item>
        <MultiLangFields basePath={["translation"]} />
        <Form.Item
          name="viewType"
          label="Вид отображения"
          rules={[{ required: true }]}
        >
          <Radio.Group
            className={styles.radioGroup}
            options={viewType.map((vt) => ({
              value: vt,
              label: viewTypeLabels[vt],
            }))}
          />
        </Form.Item>

        {curViewType === "bigLink" && (
          <Form.Item name="iconName" label="Иконка">
            <Select
              allowClear
              className={styles.iconSelect}
              placeholder="Выберите иконку"
              optionFilterProp="value"
              options={Object.keys(icons)
                .filter((key) => key.includes("Filled"))
                .map((key) => ({
                  label: (
                    <Space>
                      <IconRenderer iconKey={key} />
                      {key}
                    </Space>
                  ),
                  value: key,
                }))}
              showSearch
            />
          </Form.Item>
        )}

        <Form.Item
          name={["link", "type"]}
          label="Ссылка"
          rules={[{ required: true }]}
        >
          <Radio.Group
            className={styles.radioGroup}
            options={linkType.map((lt) => ({
              value: lt,
              label: linkTypeLabels[lt],
            }))}
          />
        </Form.Item>

        {curLinkType === "object" && (
          <Form.Item name={["link", "objectId"]} rules={[{ required: true }]}>
            <Select
              allowClear
              options={store.objectData.map(({ name, id }) => ({
                value: id,
                label: name,
              }))}
              showSearch
              filterOption={filterOptionByName}
            />
          </Form.Item>
        )}

        {curLinkType === "internal" && (
          <Form.Item name={["link", "url"]} rules={[{ required: true }]}>
            <Select
              allowClear
              options={internalMenuLinkUrls.map((url) => ({
                value: url,
                label: url,
              }))}
              showSearch
            />
          </Form.Item>
        )}

        {curLinkType === "external" && (
          <Form.Item
            name={["link", "href"]}
            rules={[
              {
                required: true,
                pattern: /^https?:\/\//,
                message: "Ссылка должна начинаться с http:// или https://",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        )}

        <Form.Item name="roleIds" label="Роли для отображения">
          <RoleSelect roleGroups={store.roleGroups} />
        </Form.Item>
      </WidthLimitedFields>
    );
  },
);

export const MenuExtraButtons: React.FC<PropsMenuItemFields> = observer(
  ({ store }) => (
    <ExtraTools>
      <Button
        icon={<PlusOutlined />}
        onClick={() => store.safeCreateMenuItem(true)}
        disabled={store.isNew ?? false}
      >
        Добавить подпункт
      </Button>
      <Button
        icon={<DeleteOutlined />}
        danger
        onClick={() => {
          store.startDeleteCurNode();
        }}
      >
        {t("Delete")}
      </Button>
    </ExtraTools>
  ),
);

import * as React from "react";
import { Checkbox, CheckboxRef } from "antd";
import { CheckboxProps } from "antd/lib";
import { observer } from "mobx-react-lite";
import { PropsCell2Editor } from "../PropsCell2";
import { CellInternalBox } from "./CellInternalBox";
import { KeyDef } from "../isSymbolKey";

export const Cell2Checkbox: React.FC<PropsCell2Editor<CheckboxProps>> =
  observer(({ cell, editorProps }) => {
    const { store, value, ...cellProps } = cell;
    const { cellKey } = cell;
    const iref = React.useRef<CheckboxRef>(null);
    const onKey = (e: KeyDef) => {
      store.onKeyDown(cellKey, e);
    };
    const onFocus = () => store.activate(cellKey, false);
    React.useEffect(() => {
      store.updateCell({
        ...cellProps,
        focus() {
          iref.current?.focus();
        },
        blur() {
          iref.current?.blur();
        },
      });
      const input = iref.current?.input;
      input?.addEventListener("focus", onFocus);
      input?.addEventListener("keydown", onKey);
      return () => {
        input?.removeEventListener("keydown", onKey);
        input?.removeEventListener("focus", onFocus);
      };
    });
    React.useEffect(() => {
      store.setSrcCellValue(cellKey, value);
    }, [value]);
    return (
      <CellInternalBox store={store} cellKey={cellKey}>
        <Checkbox
          ref={iref}
          {...editorProps}
          checked={!!store.cellCurValues[cellKey]}
          onChange={(e) => {
            store.setValueByKey(cellKey, e.target.checked);
          }}
        />
      </CellInternalBox>
    );
  });

import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { TableLoadParams, TableStore } from "src/components/tables/TableStore";
import {
  ZDisplayAttr,
  ZObjTableSettings,
  TableSettingsType,
} from "../ManagementPage/Obj2Tab/ObjTableSettings/ObjTableSettingsTypes";
import { getColumnSettings } from "../ManagementPage/Obj2Tab/ObjTableSettings/ObjTableControl/getColumnSettings";
import { EntityFilters, ZEntityRow } from "./EntityList/types";
import { loadTSettingsByType } from "../ManagementPage/Obj2Tab/ObjTableSettings/apiTableSettings";

export class DefaultTSettingsStore {
  constructor(readonly defSettingsKey: TableSettingsType) {
    makeAutoObservable(this);
  }

  tableStore: TableStore<ZEntityRow, EntityFilters> | null = null;

  setTableStore(store: TableStore<ZEntityRow, EntityFilters>) {
    this.tableStore = store;
  }

  defaultTSettings: ZObjTableSettings | null = null;

  setDefaultTsettings(settings: ZObjTableSettings | null) {
    this.defaultTSettings = settings;
  }

  settingsApplied: boolean = false;

  setSettingsApplied(flag: boolean) {
    this.settingsApplied = flag;
  }

  get displayAttrs(): ZDisplayAttr[] | null {
    return this.defaultTSettings?.displayAttributes ?? null;
  }

  get sortParams(): Partial<TableLoadParams<EntityFilters>> {
    const defSettings = this.defaultTSettings;
    return {
      sort: defSettings?.sort
        ? String(defSettings?.sort.attributeId)
        : undefined,
      sortOrder: defSettings?.sort?.direction,
    };
  }

  async init(objectId: number) {
    try {
      this.setDefaultTsettings(
        await loadTSettingsByType(objectId, this.defSettingsKey),
      );
    } catch (error) {
      onError(error);
    }
  }

  setTableSettings(
    columns: ZDisplayAttr[],
    params?: Partial<TableLoadParams<EntityFilters>>,
  ) {
    const { tableStore } = this;
    if (!tableStore || !columns) return;
    const settingsColumns = new Map(
      columns.map(({ id, settings }) => [String(id), settings]),
    );
    tableStore.setColumnOrder(columns.map(({ id }) => String(id)));
    tableStore.columns.forEach(({ key }) => {
      const colSettings = settingsColumns.get(key);
      tableStore.setColumnWidth(
        key,
        colSettings ? getColumnSettings(colSettings)?.width ?? 150 : 150,
      );
      tableStore.setColumnVisible(key, !!colSettings);
    });
    if (params) {
      tableStore.setParams({ ...tableStore.params, ...params });
      tableStore.reload();
    }
  }

  applySettings(withSort?: boolean) {
    const { displayAttrs, sortParams } = this;
    if (displayAttrs)
      this.setTableSettings(displayAttrs, withSort ? sortParams : undefined);
  }

  initSettings(
    tableStore: TableStore<ZEntityRow, EntityFilters> | null,
    apply?: boolean,
  ) {
    const { settingsApplied } = this;
    if (!settingsApplied && tableStore) {
      this.setTableStore(tableStore);
      this.setSettingsApplied(true);
      if (apply) this.applySettings();
    }
  }
}

import { z } from "zod";

export const zEntityFilterValue = z.object({
  name: z.string(),
  toSearch: z.string(),
});

export type ZEntityFilterValue = z.infer<typeof zEntityFilterValue>;

export type EntityFilterItem = {
  attrId: number;
  filterValue?: ZEntityFilterValue;
};

export type HierarchicalFilter = {
  filters: EntityFilterItem[];
  searchValue: string;
};

export type EntityFilterTreeData = EntityFilterItem;

import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { ZAttribute, zAttribute } from "src/types/ZAttribute";
import {
  ZObjTableSettings,
  zObjTableSettingsList,
  ZObjTableSettingsList,
  TableSettingsType,
} from "./ObjTableSettingsTypes";

export const loadObjTableSettings = async (
  objectId: number,
  type?: TableSettingsType,
): Promise<ZObjTableSettingsList> => {
  const resp = await rest.get(
    apiObjUrl(`/objects/${objectId}/settings/display`),
    { params: { type } },
  );
  return zObjTableSettingsList.parse(resp.data);
};

export const createObjTableSettings = async (
  objectId: number,
  data: ZObjTableSettingsList,
) => {
  await rest.post(apiObjUrl(`/objects/${objectId}/settings/display`), data);
};

export const updateObjTableSettings = async (
  objectId: number,
  data: ZObjTableSettingsList,
) => {
  await rest.put(apiObjUrl(`/objects/${objectId}/settings/display`), data);
};

export const loadSortableAttributes = async (
  objectId: number,
): Promise<ZAttribute[]> => {
  const resp = await rest.get(
    apiObjUrl(`/objects/${objectId}/sortable-attributes`),
  );
  return zAttribute.array().parse(resp.data);
};

export const loadTSettingsByType = async (
  objectId: number,
  type: TableSettingsType,
): Promise<ZObjTableSettings | null> => {
  const resp = await loadObjTableSettings(objectId, type);
  return resp.tables?.[0] ?? null;
};

import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Spin } from "antd";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { SettingsIconStd } from "src/common/icons";
import { ColumnSettingsList } from "src/components/tables/ColumnsSettings/ColumnSettingsList";
import styles from "./ColumnsModal.module.less";
import { ObjTableControlStore } from "../../ObjTableControl/ObjTableControlStore";
import { titleSettingsMap } from "../../ObjTableControl/titleSettingMap";

interface PropsColumnsModal {
  store: ObjTableControlStore;
}

export const ColumnsModal: React.FC<PropsColumnsModal> = observer(
  ({ store }) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { columnStore, isLoading, settings, isDisabled } = store;
    const type = settings?.type;
    const title = type ? titleSettingsMap[type] : "";
    return (
      <>
        <Button
          icon={<SettingsIconStd />}
          onClick={handleOpen}
          disabled={isDisabled}
        >
          Настроить отображение атрибутов
        </Button>
        <ModalVertFixed
          open={open}
          onCancel={handleClose}
          height="85vh"
          width={800}
          centered
          title={title}
          footer={
            <div>
              <Button
                className={styles.button}
                type="primary"
                onClick={handleClose}
              >
                Завершить
              </Button>
            </div>
          }
        >
          <Spin spinning={isLoading}>
            {columnStore && <ColumnSettingsList store={columnStore} />}
          </Spin>
        </ModalVertFixed>
      </>
    );
  },
);

import { makeAutoObservable } from "mobx";
import { ZAttribute } from "src/types/ZAttribute";
import { EntityStatesHistoryStore } from "./EntityStatesHistory/EntityStatesHistoryStore";
import { EntityAttsHistoryStore } from "./EntityAttsHistory";

export class EntityHistoryStore {
  constructor(
    public readonly entityId: number,
    public readonly stateNames: Record<number, string>,
    public readonly attributesMap: Record<number, ZAttribute>,
    public readonly attrTypesMap: Record<number, string>,
    public readonly curStateName: string,
    public readonly entityName: string,
  ) {
    makeAutoObservable(this);
  }

  reloadHistory() {
    this.statesStore.tableStore.reload();
    this.attsStore.tableStore.reload();
  }

  statesStore = new EntityStatesHistoryStore(this.entityId, this.stateNames);

  attsStore = new EntityAttsHistoryStore(
    this.entityId,
    this.attributesMap,
    this.attrTypesMap,
  );
}

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Spin, Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { Sheet2Store } from "../../Sheet2Store";
import styles from "./CellInternalBox.module.less";

interface PropsCellInternalBox {
  store: Sheet2Store;
  cellKey: string;
  children: React.ReactNode;
}

export const CellInternalBox: React.FC<PropsCellInternalBox> = observer(
  (props) => {
    const { store, cellKey, children } = props;
    const errMsg = store.errors[cellKey];
    return (
      <div className={styles.box}>
        {children}
        {errMsg && (
          <Tooltip title={errMsg}>
            <WarningOutlined className={styles.error} />
          </Tooltip>
        )}
        {store.saving.has(cellKey) && <Spin className={styles.spin} />}
      </div>
    );
  },
);

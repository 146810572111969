import * as React from "react";
import { Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import {
  Cell2Checkbox,
  Cell2Input,
  Cell2Number,
} from "src/components/Sheet2/components";
import { cell2Required } from "src/components/Sheet2/validators/cell2Required";
import { ZMCRow } from "../../../ZMeasurementChart";
import { makeMcPointKey, makeMcVersionKey } from "../../mChartCellKeys";
import { MChartEntityStore } from "../../MChartEntityStore";
import {
  makePointDependentDataSaverTol,
  updatePointDependentDataTol,
  savePointDependentValues,
  makePointDependentDataSaverScale,
  updatePointDependentDataScale,
} from "../../savePointDependentValues";
import { MCColumn, mkKey } from "../MCColumn";
import { MCSubtitle } from "./MCSubtitle";
import { MCTitle } from "./MCTitle";
import { PointSelect } from "./PointSelect";
import styles from "./MChartTable.module.less";

/* eslint no-param-reassign: "off" */

export const buildColumns = (
  store: MChartEntityStore,
  hiddenColumns: Set<string>,
): MCColumn[] => [
  {
    key: "select",
    title: <AllCheck store={store} />,
    width: "34px",
    align: "center",
    rowSpan: 2,
    cell: (row) => <RowCheck row={row} store={store} />,
  },
  {
    key: "Point",
    title: "Point",
    width: "150px",
    rowSpan: 2,
    cell: (row, posGen) => (
      <PointSelect row={row} store={store} pos={posGen.next(true)} />
    ),
  },
  {
    key: "Description",
    title: "Description",
    width: "200px",
    rowSpan: 2,
    cell: (row, posGen) => (
      <Cell2Input
        cell={{
          store: store.sheetStore,
          cellKey: makeMcPointKey(row, "description"),
          pos: posGen.next(false),
          emptyValue: "",
          value: row.mcPoint.description,
          save: async (value) => {
            const validValue = typeof value === "string" ? value : null;
            const res = await store.savePointValue(
              row,
              "description",
              validValue,
            );
            return () => store.updatePointValue(row, res, "description");
          },
        }}
        editorProps={{ allowClear: true }}
      />
    ),
  },
  {
    key: "qc",
    title: "QC",
    width: "40px",
    cls: styles.fullCentered,
    rowSpan: 2,
    cell: (row, posGen) => (
      <Cell2Checkbox
        cell={{
          store: store.sheetStore,
          cellKey: makeMcPointKey(row, "qc"),
          pos: posGen.next(false),
          value: row.mcPoint.qc,
          emptyValue: false,
          save: async (rawValue) => {
            const value = typeof rawValue === "boolean" ? rawValue : false;
            const res = await store.savePointValue(row, "qc", value);
            return () => store.updatePointValue(row, res, "qc");
          },
        }}
        editorProps={{}}
      />
    ),
  },
  {
    key: "resizeValueMinus",
    title: "Scale -",
    width: "60px",
    rowSpan: 2,
    cell: (row, posGen) => (
      <Cell2Number
        cell={{
          store: store.sheetStore,
          cellKey: makeMcPointKey(row, "resizeValueMinus"),
          pos: posGen.next(false),
          value: row.mcPoint.resizeValueMinus,
          emptyValue: null,
          save: async (value) => {
            const res = await savePointDependentValues(
              {
                mcStore: store,
                field: "resizeValueMinus",
                row,
                value: typeof value === "number" ? value : null,
              },
              [
                makePointDependentDataSaverScale({
                  mcStore: store,
                  field: "resizeValuePlus",
                  row,
                  value: typeof value === "number" ? value : null,
                }),
              ],
            );
            return () => {
              updatePointDependentDataScale(
                store,
                row,
                res,
                "resizeValueMinus",
                "resizeValuePlus",
              );
            };
          },
        }}
        editorProps={{ min: 0 }}
      />
    ),
  },
  {
    key: "resizeValuePlus",
    title: "Scale +",
    width: "60px",
    rowSpan: 2,
    cell: (row, posGen) => (
      <Cell2Number
        cell={{
          cellKey: makeMcPointKey(row, "resizeValuePlus"),
          pos: posGen.next(false),
          value: row.mcPoint.resizeValuePlus,
          emptyValue: null,
          store: store.sheetStore,
          save: async (rawValue) => {
            const value = typeof rawValue === "number" ? rawValue : null;
            const result = await savePointDependentValues(
              {
                mcStore: store,
                field: "resizeValuePlus",
                row,
                value,
              },
              [
                makePointDependentDataSaverScale({
                  mcStore: store,
                  field: "resizeValueMinus",
                  row,
                  value,
                }),
              ],
            );
            return () =>
              updatePointDependentDataScale(
                store,
                row,
                result,
                "resizeValuePlus",
                "resizeValueMinus",
              );
          },
        }}
        editorProps={{ min: 0 }}
      />
    ),
  },
  {
    key: "tolMinus",
    title: "Tol -",
    width: "70px",
    rowSpan: 2,
    cell: (row, posGen) => (
      <Cell2Number
        cell={{
          store: store.sheetStore,
          cellKey: makeMcPointKey(row, "tolMinus"),
          pos: posGen.next(false),
          value: row.mcPoint.tolMinus,
          emptyValue: null,
          validate: cell2Required,
          save: async (rawValue) => {
            const value = typeof rawValue === "number" ? rawValue : null;
            const result = await savePointDependentValues(
              {
                mcStore: store,
                field: "tolMinus",
                row,
                value,
              },
              [
                makePointDependentDataSaverTol({
                  mcStore: store,
                  field: "tolPlus",
                  row,
                  value,
                }),
              ],
            );
            return () =>
              updatePointDependentDataTol(
                store,
                row,
                result,
                "tolMinus",
                "tolPlus",
              );
          },
        }}
        editorProps={{ min: 0 }}
      />
    ),
  },
  {
    key: "tolPlus",
    title: "Tol +",
    width: "70px",
    rowSpan: 2,
    cell: (row, posGen) => (
      <Cell2Number
        cell={{
          store: store.sheetStore,
          cellKey: makeMcPointKey(row, "tolPlus"),
          pos: posGen.next(false),
          value: row.mcPoint.tolPlus,
          emptyValue: null,
          save: async (rawValue) => {
            const value = typeof rawValue === "number" ? rawValue : null;
            const result = await savePointDependentValues(
              {
                mcStore: store,
                field: "tolPlus",
                row,
                value,
              },
              [
                makePointDependentDataSaverTol({
                  mcStore: store,
                  field: "tolMinus",
                  row,
                  value,
                }),
              ],
            );
            return () =>
              updatePointDependentDataTol(
                store,
                row,
                result,
                "tolPlus",
                "tolMinus",
              );
          },
          validate: cell2Required,
        }}
        editorProps={{ min: 0 }}
      />
    ),
  },
  ...store.sizeColumns.flatMap(({ name: szName }) => {
    const verSet = store.sizeVersions[szName];
    const srcList: string[] = verSet ? Array.from(verSet) : [];
    const verList: string[] = srcList.filter(
      (verName) => !hiddenColumns.has(mkKey(szName, verName)),
    );
    return verList.map(
      (verName, i) =>
        ({
          key: mkKey(szName, verName),
          szName,
          verName,
          noPadTitle: true,
          title:
            i === 0 ? (
              <MCTitle
                szName={szName}
                onAddVersion={
                  store.canAddVersion(szName)
                    ? () => store.addVersion(szName)
                    : undefined
                }
                disabled={!!store.buzy}
              />
            ) : null,
          colSpan: i === 0 ? verList.length : undefined,
          subTitle: (
            <MCSubtitle
              verName={verName}
              onDelete={
                store.canDeleteVersion(szName, verName)
                  ? () => store.deleteVersion(szName, verName)
                  : undefined
              }
            />
          ),
          width: "1fr",
          isBaseSize: szName === store.curBaseSizeName,
          cell: (row, posGen) => (
            <Cell2Number
              cell={{
                store: store.sheetStore,
                cellKey: makeMcVersionKey(row.mcPoint.id, szName, verName),
                pos: posGen.next(false),
                value: store.getVersionValue(row, szName, verName),
                emptyValue: undefined,
                save: async (rawValue) => {
                  const value = typeof rawValue === "number" ? rawValue : 0;
                  const res = await store.saveVersionValue(
                    row,
                    szName,
                    verName,
                    value,
                  );
                  return () => store.updateVersionValue(res);
                },
                validate: cell2Required,
              }}
              editorProps={{ disabled: verList.length - 1 !== i, min: 0 }}
            />
          ),
        }) satisfies MCColumn,
    );
  }),
];

interface RowProps {
  row: ZMCRow;
  store: MChartEntityStore;
}

interface TableProps {
  store: MChartEntityStore;
}

const AllCheck: React.FC<TableProps> = observer(({ store }) => (
  <Checkbox
    checked={store.selectStatus === "all"}
    indeterminate={store.selectStatus === "partial"}
    onChange={() => store.toggleSelectAll()}
  />
));

const RowCheck: React.FC<RowProps> = observer(({ row, store }) => (
  <Checkbox
    checked={store.selected.has(row.mcPoint.id)}
    onChange={() => store.toggleSelect(row.mcPoint.id)}
  />
));

export type KeyDef = {
  key: string;
  code: string;
  shiftKey: boolean;
};

export const isSymbolKey = (e: KeyDef) =>
  // Буквы: code = Key*
  // Верхние числа: code = Digit*
  // Правые числа: key = 0..9
  // TODO: пунктуации пока нет
  /^(Key)|(Digit)/.test(e.code) || /^[\d]$/.test(e.key);

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Flex } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { ObjectO2 } from "../Obj2Nodes";
import { Obj2TabStore } from "../Obj2TabStore";
import { ObjTableControl } from "./ObjTableControl";
import styles from "./ObjTableSettings.module.less";
import { UniformlySwitch } from "./controls/UniformlySwitch";

interface PropsObjTableSettings {
  store: Obj2TabStore;
  objNode: ObjectO2;
}

export const ObjTableSettings: React.FC<PropsObjTableSettings> = observer(
  ({
    store: { objTableSettingsStore: settingsStore },
    objNode: { object },
  }) => {
    const { initialSettings, sortingStores } = settingsStore;
    const { id } = object;

    useEffect(() => {
      settingsStore.init(id);
    }, [id]);

    return (
      <LoaderBox
        remoteData={initialSettings}
        drawReady={(data) => (
          <div className={styles.box}>
            <Flex vertical gap={32}>
              <UniformlySwitch store={settingsStore} />
              {data.tables?.map((settingsEl) => {
                const { type } = settingsEl;
                const setts = sortingStores.get(type);
                if (!setts) return null;
                return (
                  <ObjTableControl
                    key={type}
                    objectId={id}
                    initialSettings={settingsEl}
                    store={setts}
                  />
                );
              })}
            </Flex>
          </div>
        )}
      />
    );
  },
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-EntityList-DefaultViewButton-DefaultViewButton-module__settingsButton--S5xAC {
  width: 100%;
  padding: 10px;
  height: auto;
  border-radius: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityList/DefaultViewButton/DefaultViewButton.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":[".settingsButton {\n  width: 100%;\n  padding: 10px;\n  height: auto;\n  border-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsButton": `src-pages-EntityFiltersPage-EntityList-DefaultViewButton-DefaultViewButton-module__settingsButton--S5xAC`
};
export default ___CSS_LOADER_EXPORT___;

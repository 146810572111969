// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-AttributeHistory-components-AttrImage-AttrImage-module__attImage--SEjF6 {
  margin-top: 8px;
  gap: 3px;
  display: flex;
  flex-wrap: wrap;
}
.src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-AttributeHistory-components-AttrImage-AttrImage-module__attImage--SEjF6 img {
  border: 1px solid #0000000f;
  width: 90px;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityHistory/EntityAttsHistory/AttributeHistory/components/AttrImage/AttrImage.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,aAAA;EACA,eAAA;AACF;AALA;EAOI,2BAAA;EACA,WAAA;EACA,mBAAA;AACJ","sourcesContent":[".attImage {\n  margin-top: 8px;\n  gap: 3px;\n  display: flex;\n  flex-wrap: wrap;\n\n  img {\n    border: 1px solid #0000000f;\n    width: 90px;\n    object-fit: contain;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attImage": `src-pages-EntityCardPage-EntityHistory-EntityAttsHistory-AttributeHistory-components-AttrImage-AttrImage-module__attImage--SEjF6`
};
export default ___CSS_LOADER_EXPORT___;

import { z } from "zod";

const zAuditEntityAttAction = z.enum(["add", "edit", "delete"]);
export type ZAuditEntityAttAction = z.infer<typeof zAuditEntityAttAction>;

export const zAuditEntityAtt = z.object({
  id: z.number(),
  name: z.string(),
  value: z.string().nullable(),
  valueType: z.number(),
  action: zAuditEntityAttAction,
});
export type ZAuditEntityAtt = z.infer<typeof zAuditEntityAtt>;

export const zAuditEntityValue = z.object({
  userId: z.string(),
  fio: z.string().nullable(),
  email: z.string().nullable(),
  timestamp: z.string(),
  attributes: zAuditEntityAtt.array(),
});
export type ZAuditEntityValue = z.infer<typeof zAuditEntityValue>;

export type AuditEntityValueFilters = {
  entityId: number;
  timestamp?: string[];
  userId?: string;
  attributeId?: number;
};

export const zAuditEntityValueResponse = z.object({
  totalElements: z.number(),
  content: zAuditEntityValue.array(),
});

import * as React from "react";
import { AttrTypeName } from "src/types/AttrType";
import { AttrData } from "./AttributeHistrory.types";
import { AttrUsersList } from "./components/AttrUsersList";
import { AttrImage } from "./components/AttrImage";
import { AttrFile } from "./components/AttrFile";
import { AttrLanguage } from "./components/AttrLanguage";
import { AttrObject } from "./components/AttrObject";
import { AttrBoolean } from "./components/AttrBoolean";
import { AttrDate } from "./components/AttrDate";
import { AttrDateTime } from "./components/AttrDateTime";
import { AttrDictMulti } from "./components/AttrDictMulti";

const AttrText: React.FC<AttrData> = ({ value }) => value;

const viewByType: Record<AttrTypeName, React.FC<AttrData>> = {
  [AttrTypeName.boolean]: AttrBoolean,
  [AttrTypeName.date]: AttrDate,
  [AttrTypeName.dateTime]: AttrDateTime,
  [AttrTypeName.dictMulti]: AttrDictMulti,
  [AttrTypeName.dictSingle]: AttrText,
  [AttrTypeName.file]: AttrFile,
  [AttrTypeName.image]: AttrImage,
  [AttrTypeName.int]: AttrText,
  [AttrTypeName.number]: AttrText,
  [AttrTypeName.string]: AttrText,
  [AttrTypeName.time]: AttrText,
  [AttrTypeName.object]: AttrObject,
  [AttrTypeName.usersList]: AttrUsersList,
  [AttrTypeName.childEntities]: AttrText,
  [AttrTypeName.externalLink]: AttrText,
  [AttrTypeName.formula]: AttrText,
  [AttrTypeName.contentLanguage]: AttrLanguage,
  [AttrTypeName.linkedValue]: AttrText,
  [AttrTypeName.article]: AttrText,
  [AttrTypeName.financial]: AttrText,
};

export const AttributeHistory: React.FC<{
  type: AttrTypeName;
  value: string;
}> = ({ type, value }) => {
  const Component = viewByType[type];
  return <Component value={value} />;
};

/**
 * Главная часть экрана в том случае, когда в левом дереве выбран узел-объект
 * Предполагает табы с разной функциональностью
 */
import { TabsProps, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ServSettingsForm } from "../forms/ServSettingsForm";
import { ObjectO2 } from "../Obj2Nodes";
import { Obj2Orders } from "../Obj2Orders";
import { Obj2TabStore } from "../Obj2TabStore";
import { ObjNodeEditor } from "../ObjNodeEditor";
import styles from "./Obj2Content.module.less";
import { ObjTableSettings } from "../ObjTableSettings";

interface PropsObj2Content {
  node: ObjectO2;
  store: Obj2TabStore;
}

export const Obj2Content: React.FC<PropsObj2Content> = observer(
  ({ node, store }) => {
    const items: TabsProps["items"] = [
      {
        key: "objFields",
        label: "Управление",
        children: <ObjNodeEditor curNode={node} store={store} />,
      },
      {
        key: "objOrders",
        label: "Порядок групп и атрибутов",
        children: <Obj2Orders objNode={node} store={store} />,
      },
      {
        key: "objTableSettings",
        label: "Отображение списка экземпляров",
        children: <ObjTableSettings objNode={node} store={store} />,
      },
      ...(node.services ?? []).map((servInfo) => ({
        key: `serv:${servInfo.id}`,
        label: servInfo.name,
        children: (
          <ServSettingsForm store={store} objNode={node} servInfo={servInfo} />
        ),
      })),
    ];
    const [active, setActive] = React.useState("objFields");
    return (
      <Tabs
        className={styles.tabs}
        items={items}
        activeKey={active}
        onChange={(key) => store.formStore.safeAction(() => setActive(key))}
      />
    );
  },
);

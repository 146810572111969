import { zColumnSettings, ZColumnSettings } from "../ObjTableSettingsTypes";

export const getColumnSettings = (
  settings: string | null,
): ZColumnSettings | null => {
  try {
    if (!settings) return null;
    const json = JSON.parse(settings);
    return zColumnSettings.parse(json);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

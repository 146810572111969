import { makeAutoObservable } from "mobx";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { SelectProps } from "antd";
import { ZAttribute } from "src/types/ZAttribute";
import { DefaultOptionType } from "antd/es/select";
import {
  ZAuditEntityValue,
  AuditEntityValueFilters,
} from "./EntityAttsHistory.types";
import {
  loadEntityAttsHistory,
  loadValuesUserFilter,
} from "./apiEntityAttsHistory";
import { historyLoader } from "../apiEntityHistory";
import { ZAuditUserFilter } from "../EntityStatesHistory/EntityStatesHistory.types";

export class EntityAttsHistoryStore {
  constructor(
    public readonly entityId: number,
    public readonly attributesMap: Record<number, ZAttribute>,
    public readonly attrTypesMap: Record<number, string>,
  ) {
    makeAutoObservable(this);
  }

  async init() {
    try {
      const users = await loadValuesUserFilter(this.entityId);
      this.setUserFilterList(users);
    } catch (error) {
      onError(error);
    }
  }

  userFilterList: ZAuditUserFilter[] | null = null;

  setUserFilterList(users: ZAuditUserFilter[]) {
    this.userFilterList = users;
  }

  get userFilterOptions(): DefaultOptionType[] {
    const { userFilterList } = this;
    if (!userFilterList) return [];
    return (
      userFilterList
        .filter(({ fio, userId }) => fio && userId)
        .map(({ fio, userId }) => ({
          label: fio,
          value: userId,
        })) ?? []
    );
  }

  get attributeFilterOptions(): SelectProps["options"] {
    return Object.keys(this.attributesMap).map((attKey) => {
      const att = Number(attKey);
      return {
        label: this.attributesMap[att]?.name,
        value: att,
      };
    });
  }

  tableStore = new TableStore<ZAuditEntityValue, AuditEntityValueFilters>({
    rowKey: "timestamp",
    fnLoad: historyLoader(this.entityId, loadEntityAttsHistory),
  });
}

import * as React from "react";
import { CategoryRefPage } from "src/pages/CategoryRefPage";
import { HomePage } from "src/pages/HomePage";
import { ModelConstructorPage } from "src/pages/ModelConstructorPage";
import {
  TestTablePage,
  TestCardEditPage,
  TestCardNewPage,
} from "src/pages/TestTablePage";
import { ModelessFormTestPage } from "src/pages/ModelessFormTestPage";
import { ModelsListPage } from "src/pages/ModelsListPage";
import { ManagementPage } from "src/pages/ManagementPage";
import { EntityCardPage, NewEntityCardPage } from "src/pages/EntityCardPage";
import { EntityFiltersPage } from "src/pages/EntityFiltersPage";
import { TestPage } from "src/pages/TestPage";
import { UserAccountPage } from "src/pages/UserAccountPage";
import { DashboardsPage } from "src/pages/DashboardsPage";
import { Sheet2Page } from "src/pages/Sheet2Page";
import { PageUrl } from "./PageUrl";

export const routesMap: Record<PageUrl, React.FC> = {
  [PageUrl.categories]: CategoryRefPage,
  [PageUrl.dashboards]: DashboardsPage,
  [PageUrl.entityNew]: NewEntityCardPage,
  [PageUrl.entityCard]: EntityCardPage,
  [PageUrl.home]: HomePage,
  [PageUrl.management]: ManagementPage,
  [PageUrl.modelConstructor]: ModelConstructorPage,
  [PageUrl.modelessForm]: ModelessFormTestPage,
  [PageUrl.models]: ModelsListPage,
  [PageUrl.testCardEdit]: TestCardEditPage,
  [PageUrl.testCardNew]: TestCardNewPage,
  [PageUrl.testTable]: TestTablePage,
  [PageUrl.userAccount]: UserAccountPage,
  [PageUrl.entities]: EntityFiltersPage,
  [PageUrl.test]: TestPage,
  [PageUrl.sheet2]: Sheet2Page,
};

export const routes = Object.entries(routesMap);

import * as React from "react";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { AttrData } from "../../AttributeHistrory.types";
import styles from "./AttrImage.module.less";

export const AttrImage: React.FC<AttrData> = ({ value }) => (
  <div className={styles.attImage}>
    {value.split(",").map((uuid) => (
      <img key={uuid} src={imgSrc(uuid)} alt="" />
    ))}
  </div>
);

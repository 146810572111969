// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-ObjTableControl-module__tableContainer--yCJWJ {
  height: 100%;
  max-height: 40vh;
}
.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-ObjTableControl-module__title--KB1xO {
  color: #000000D9;
  font-weight: 500;
  font-size: 16px;
}
.src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-ObjTableControl-module__sortBlock--gS_xw {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 50%;
  gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/ObjTableSettings/ObjTableControl/ObjTableControl.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;AAAF;AAGA;EACE,aAAA;EACA,8BAAA;EACA,UAAA;EACA,SAAA;AADF","sourcesContent":[".tableContainer {\n  height: 100%;\n  max-height: 40vh;\n}\n\n.title {\n  color: #000000D9;\n  font-weight: 500;\n  font-size: 16px;\n}\n\n.sortBlock {\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  width: 50%;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-ObjTableControl-module__tableContainer--yCJWJ`,
	"title": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-ObjTableControl-module__title--KB1xO`,
	"sortBlock": `src-pages-ManagementPage-Obj2Tab-ObjTableSettings-ObjTableControl-ObjTableControl-module__sortBlock--gS_xw`
};
export default ___CSS_LOADER_EXPORT___;
